import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import importKey from "../../utils/validation/importKey";
import {
  useImportKeyDsaMutation,
  useImportKeyRsaMutation,
} from "../../store/feature/service/importKeyApiSlice";
import Swal from "sweetalert2";

const ImportKeyField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("detail");

  const [fileContent, setFileContent] = useState("");

  const [
    importDsa,
    { isSuccess: successDsa, isError: isErrorDsa, data: dataDsa },
  ] = useImportKeyDsaMutation();
  const [
    importRsa,
    { isSuccess: successRsa, isError: isErrorRsa, data: dataRsa },
  ] = useImportKeyRsaMutation();


  // REGION: INIT FORM DATA
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    KeyType: string;
    labelName: string;
    pemFile: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(importKey),
    defaultValues: {
      KeyType: "",
      labelName: "",
      pemFile: "",
    },
  });

  const optRoleSelect = [
    {
      label: "RSA",
      value: "RSA",
    },
    {
      label: "ECDSA",
      value: "ECDSA",
    },
  ];


  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    console.log("saiki ===> ", e.labelName);
    if (e.keyType === "ECDSA") {
      importDsa({label: e.labelName, pem: fileContent });
    } else {
      importRsa({label: e.labelName, pem: fileContent });
    }
  };

  useEffect(() => {
    if (successDsa) {
      Swal.fire({
        icon: "success",
        title: "Success Import Key",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Your ECDSA Public Key is imported!",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => navigate("/import-key"));
    }
  }, [successDsa]);

  useEffect(() => {
    if (successRsa) {
      Swal.fire({
        icon: "success",
        title: "Success Import Key",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Your RSA Public Key is imported!",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => navigate("/import-key"));
    }
  }, [successRsa]);

  useEffect(() => {
    if (isErrorDsa) {
      Swal.fire({
        icon: "error",
        title: "Error Import Key",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Import ECDSA Key",
      });
    }
  }, [isErrorDsa]);

  useEffect(() => {
    if (isErrorRsa) {
      Swal.fire({
        icon: "error",
        title: "Error Import Key",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Import RSA Key",
      });
    }
  }, [isErrorRsa]);

  const handleFileChange = (file: any) => {
    let fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      const content = event.target.result;
      console.log("content ===> ", content);
      setFileContent(content);
    };
    fileReader.readAsText(file[0]);
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {/* {editFgroup ? "Update Key" : "Create Key"} */}
          Import Key
        </Typography>
        <SelectFormm
          name="keyType"
          label="Key Type"
          defaultValue={0}
          options={optRoleSelect}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          isSearch
          required
        />
        <InputForm
          name="labelName"
          label="Label Name"
          placeholder="type Label Name"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="pemFile"
          label="Upload PEM File"
          placeholder="keyName"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="file"
          onChange={handleFileChange}
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/key")}
          >
            Back
          </Button>
          {editFgroup && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )}
          {!editFgroup && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default ImportKeyField;
