import IRoutes from "../interfaces/IRoutes";
import DddFieldsMng from "../pages/dddFields/DddFieldsMng";
import DddFieldsMngDetail from "../pages/dddFields/DddFieldsMngDetail";
import DddFieldsMngField from "../pages/dddFields/DddFieldsMngField";

const DdddFieldsRoute: Array<IRoutes> = [
    {
        element: <DddFieldsMng />,
        path: "/ddd-fields",
    },
    {
        element: <DddFieldsMngField />,
        path: "/ddd-fields/create",
    },
    {
        element: <DddFieldsMngField />,
        path: "/ddd-fields/edit/:id",
    },
    {
        element: <DddFieldsMngDetail />,
        path: "/ddd-fields/detail/:id",
    }
];

export { DdddFieldsRoute };
