import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const KeyMngSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [keyName, setKeyName] = useState("");
  const [length, setLength] = useState("");
  const [keyType, setKeyType] = useState("");
  const [enrolledStart, setEnrolledStart] = useState("");
  const [enrolledEnd, setEnrolledEnd] = useState("");
  const [expiryStart, setExpiryStart] = useState("");
  const [expiryEnd, setExpiryEnd] = useState("");
  const [userActive, setUserActive] = useState(""); // TODO: NEED PARAMETER FROM API
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    keyName: string;
    length: string;
    keyType: string;
    enrolledStart: string;
    enrolledEnd: string;
    expiryStart: string;
    expiryEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      keyName: "",
      length: "",
      keyType: "",
      enrolledStart: "",
      enrolledEnd: "",
      expiryStart: "",
      expiryEnd: "",
    },
  });

  const keyTypeDropdown = [
    {
      label: "RSA",
      value: "RSA",
    },
    {
      label: "AES",
      value: "AES",
    },
    {
      label: "ECDSA",
      value: "ECDSA",
    },
    {
      label: "HMACSHA256",
      value: "HMACSHA256",
    },
    {
      label: "HMACSHA384",
      value: "HMACSHA384",
    },
    {
      label: "HMACSHA512",
      value: "HMACSHA512",
    },
  ];

  const handleKeyName = (e: string) => {
    setKeyName(e);
  };
  const handleLength = (e: string) => {
    setLength(e);
  };
  // const handleStatusSelect = (e: any) => { setUserActive(e.value); };
  const handleEnrolledStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(expiryEnd))) {
        setAllDate(NewDate);
      } else {
        setEnrolledStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      // deviceId: deviceId,
      // hostname: hostname,
      // enrolledStart: date,
      // enrolledEnd: date,
      // status: userActive,
      keyName,
      length,
      keyType,
      enrolledStart: date,
      enrolledEnd: date,
    });
    setEnrolledStart(date);
    setEnrolledEnd(date);
  };
  const handleEnrolledEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setEnrolledEnd(NewDate);
  };
  const conditionCheck = () => {
    if (enrolledStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Created",
      });
      reset({
        keyName,
        length,
        keyType,
        enrolledStart: "",
        enrolledEnd: "",
      });
      setEnrolledEnd("");
      return false;
    } else {
      return true;
    }
  };

  //expiry start
    // const handleStatusSelect = (e: any) => { setUserActive(e.value); };
    const handleExpiryStart = (e: any) => {
      let NewDate: any;
      if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
        if (dayjs(e).isAfter(dayjs(enrolledEnd))) {
          setAllDateExpiry(NewDate);
        } else {
          setExpiryStart(NewDate);
        }
      } else {
        NewDate = "";
        setAllDateExpiry(NewDate);
      }
    };
    const setAllDateExpiry = (date: string) => {
      reset({
        keyName,
        length,
        keyType,
        enrolledStart,
        enrolledEnd,
        expiryStart: date,
        expiryEnd: date,
      });
      setExpiryStart(date);
      setExpiryEnd(date);
    };
    const handleExpiryEnd = (e: any) => {
      let NewDate: any;
      if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
      } else {
        NewDate = "";
      }
      if (conditionCheckExpiry()) setExpiryEnd(NewDate);
    };
    const conditionCheckExpiry = () => {
      if (enrolledStart === "") {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#051438",
          text: "Please select Start Created",
        });
        reset({
          keyName,
          length,
          keyType,
          enrolledStart: "",
          enrolledEnd: "",
        });
        setExpiryEnd("");
        return false;
      } else {
        return true;
      }
    };
  //expiry end

  useDebouncedEffect(() => setSearchFilterDebounced(keyName), [keyName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(length), [length], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(keyType), [keyType], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledStart),
    [enrolledStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledEnd),
    [enrolledEnd],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(expiryStart),
    [expiryStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(expiryEnd),
    [expiryEnd],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      name: keyName,
      dddName: length,
      keyType: keyType,
      createdFrom: enrolledStart,
      createdTo: enrolledEnd,
      expiryFrom: expiryStart,
      expiryTo: expiryEnd,
      // param_filter_status: userActive,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="keyName"
          errors={errors}
          control={control}
          required
          placeholder="Key Name"
          maxLength={100}
          isLoading={false}
          onChange={handleKeyName}
        />
        <DatePickerForm
          name="enrolledStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Creation Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledStart}
        />
        <DatePickerForm
          name="enrolledEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Creation End"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledEnd}
        />
        <DatePickerForm
          name="expiryStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Expiry Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleExpiryStart}
        />
        <DatePickerForm
          name="expiryEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Expiry End"
          disabled={false}
          isLoading={false}
          required
          onChange={handleExpiryEnd}
        />
        <InputForm
          name="length"
          errors={errors}
          control={control}
          required
          placeholder="DDD Name"
          maxLength={100}
          isLoading={false}
          onChange={handleLength}
        />
      </Box>
    </Box>
  );
};

export default KeyMngSearch;
