import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, RadioForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import pageMasterEndpoint from "../../utils/validation/pageMasterEndpoint";
import {
  useCreatePageMasterEndpointMutation,
  useGetPageMastrEndpntByUidMutation,
  useUpdatePageMasterEndpointMutation,
} from "../../store/feature/service/PageMasterEndpoint";
import PageMasterEndpointTable from "../../components/molecules/pageMaster/PageMasterEndpointTable";

const PageMasterEndpoint = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optAccGroup, setOtpAccGroup] = useState([]);
  const [params, setParams] = useState<boolean>(false);
  const [updateParams, setUpdateParams] = useState<any>({})
  const [update, setUpdate]= useState(false)

  // const {
  //   data: dataRoleList,
  //   isError: errRoleList,
  //   isSuccess: succRoleList,
  // } = useRoleListQuery({});
  const [
    CreatePageEndpoint,
    { data: dataCreate, isError: isErrorCreate, isSuccess: isSuccesCreate },
  ] = useCreatePageMasterEndpointMutation();

  // useGetPageMastrEndpntByUidQuery
  const [
    GetPageMastrEndpntByUid,
    {
      data: dataGetByUid,
      isError: isErrorGetByUid,
      isSuccess: isSuccesGetByUid,
    },
  ] = useGetPageMastrEndpntByUidMutation();
  // useUpdateDACertMutation
  const [
    UpdatePageMasterEndpoint,
    { data: dataUpdate, isError: isErrorUpdate, isSuccess: isSuccesUpdate },
  ] = useUpdatePageMasterEndpointMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    endpointPageName: string;
    endpointMethod: string;
    endpointPath: string;
    endpointUid: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(pageMasterEndpoint),
    defaultValues: {
      endpointPageName: "",
      endpointMethod: "",
      endpointPath: "",
      endpointUid: "",
    },
  });

  useEffect(() => {
    if (state) {
      GetPageMastrEndpntByUid(state.name);
      console.log('state ===> ', state)
    }
  }, [state, params, isSuccesUpdate]);

  useEffect(()=> {
    if(updateParams){
      setUpdate(true)
      const methods = updateParams.method !== undefined ? updateParams.method.toLowerCase() : ''
      setValue("endpointMethod", methods);
      setValue("endpointPageName", updateParams.pageName);
      setValue("endpointPath", updateParams.endpointPath);
      setValue("endpointUid", updateParams.endpointUid);
    }
  }, [updateParams])

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    let tempObj = {
      adm_endpoint_page_endpoint_path: e.endpointPath,
      adm_endpoint_page_method: e.endpointMethod,
      adm_endpoint_page_name: e.endpointPageName,
      pge_master_uid: idParam.id,
    };

    // {
    //   pge_master_access_url: e.endpointMethod,
    //   pge_master_code: e.endpointPath,
    //   pge_master_name: e.endpointPageName,
    // };
    // console.log("tempObj ===> ", e);
    if (update) {
      UpdatePageMasterEndpoint({ id: e.endpointUid, body: tempObj });
    } else {
      CreatePageEndpoint(tempObj);
      setParams((prev: any)=> !prev)
    }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/page-master");
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };
  const optendpointMethod = [
    {
      label: "Get",
      value: "get",
    },
    {
      label: "Post",
      value: "post",
    },
    {
      label: "Put",
      value: "put",
    },
    {
      label: "Delete",
      value: "delete",
    },
  ];

  useEffect(() => {
    if (isSuccesCreate) {
      Swal.fire({
        icon: "success",
        title: "Success Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Page Master Endpoint",
      })
      .then(() => setParams((prev: any)=> !prev));
    }
  }, [isSuccesCreate]);

  useEffect(() => {
    if (isErrorCreate) {
      Swal.fire({
        icon: "error",
        title: "Error Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Upload Page Master Endpoint",
      });
    }
  }, [isErrorCreate]);

  // useEffect(() => {
  //   if (isSuccesUpdate) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Success Update",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Success Update User Account ",
  //     }).then(() => navigate("/page-master"));
  //   }
  // }, [isSuccesUpdate]);

  // useEffect(() => {
  //   if (isErrorUpdate) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error Update",
  //       showCancelButton: false,
  //       confirmButtonText: "OK",
  //       confirmButtonColor: "#051438",
  //       text: "Error Update User Account ",
  //     });
  //   }
  // }, [isErrorUpdate]);

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Typography variant="h3" fontFamily="Open Sans" mb={3}>
              Master Page - Endpoint
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="endpointPageName"
              label="Endpoint Page Name"
              placeholder="Type Page Name"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <RadioForm
              name="endpointMethod"
              label="Endpoint Method"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
              options={optendpointMethod}
              defaultValue={""}
            />
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="endpointPath"
              label="Endpoint Path"
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item lg={12} mb={5}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                type="button"
                color="gray"
                onClick={handleRevoke}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                {editFgroup ? "Update" : "Submit"}
              </Button>
            </Stack>
          </Grid>
          <Grid item lg={12}>
            <PageMasterEndpointTable
              data={
                dataGetByUid !== undefined && dataGetByUid !== null
                  ? dataGetByUid?.data
                  : []
              }
              setParams={setParams}
              setUpdate={setUpdateParams}
            />
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default PageMasterEndpoint;
