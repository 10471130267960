import * as yup from "yup";
import { validationArraySelect, validationConfirmNewPasswordL, validationEmailField, validationNewPassword, validationNotEmpty, validationStringSelect } from "./defaultValidations";

export default yup.object({
  accessGroupName: validationNotEmpty("Access Group Name"),
  description: validationNotEmpty("Description"),
  status: validationStringSelect("Status"),
});


     
     
     