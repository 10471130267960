import {
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteKeyMngMutation } from "../../../store/feature/service/keyApiSlice";
import { useEffect, useState } from "react";
import {
  useDeleteGeneratedDigsigMutation,
  useDownloadGenDigsigMutation,
} from "../../../store/feature/service/generateDigsigApiSlice";
import { QRCodeCanvas } from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";

const GeneratedDigsigTable = ({ data, params, setParams }: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const { digsigData } = useAppSelector((state: RootState) => state.role);
  const [open, setOpen] = useState(false);
  const [qrGenerated, setQrGenerated] = useState("");
  const [
    deleteGeneratedDig,
    {
      isSuccess: successDeleteGeneratedDig,
      isError: isErrorDeleteGeneratedDig,
      data: dataDeleteGeneratedDig,
      error: errorDeleteGeneratedDig,
    },
  ] = useDeleteGeneratedDigsigMutation();

  const [
    DownloadGenDigsig,
    {
      isSuccess: successDownloadDigsig,
      isError: isErrorDownloadDigsig,
      data: dataDownloadDigsig,
      error: errorDownloadDigsig,
    },
  ] = useDownloadGenDigsigMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/digsig-data/detail/${params.row.entryUid}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteGeneratedDig(params.row.entryUid);
        }
        console.log("kid ===> ", {
          kid: params.row.kid,
        });
      });
    };

    const onUpdate = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/digsig-data/edit/${params.row.entryUid}`);
    };

    const onDownloadBase64 = (e: any) => {
      DownloadGenDigsig(params.row.entryUid);
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            View
          </Button>
          {digsigData.update &&
            (params.row.status === "generated" ? (
              <Button variant="contained" onClick={onDownloadBase64}>
                Download base64...
              </Button>
            ) : (
              <Button variant="contained" onClick={onUpdate}>
                Update
              </Button>
            ))}
          {digsigData.delete && (
            <Button variant="contained" color="error" onClick={onDelete}>
              Delete
            </Button>
          )}
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "entryName",
      headerName: "Entry Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "company",
      headerName: "Company",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "cratedAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        if (params.row.status !== null) {
          switch (params.row.status.toLowerCase()) {
            case "generated": {
              return (
                <Chip label="Generated" color="success" variant="outlined" />
              );
            }
            case "not generated": {
              return (
                <Chip
                  label="Not Generated"
                  color="warning"
                  variant="outlined"
                />
              );
            }
          }
        } else {
          return "";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 370,
      headerAlign: "center",
      // align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data?.data);
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  const rows = dataRows.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    uid: row.dsg_ddd_uid,
    entryName: row.dsg_ddd_entry_name,
    entryUid: row.dsg_ddd_entry_uid,
    company: row.dsg_ddd_entry_company,
    cratedAt: row.dsg_ddd_entry_created_at,
    status: row.dsg_status,
  }));

  useEffect(() => {
    if (dataDownloadDigsig) {
      setQrGenerated(dataDownloadDigsig?.data?.dsg_generated_raw_base64);
      setOpen(true);
    }
  }, [dataDownloadDigsig]);

  useEffect(() => {
    if (successDeleteGeneratedDig) {
      Swal.fire({
        icon: "success",
        title: "Success Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Digsig Certificate",
      });
    }
  }, [successDeleteGeneratedDig]);

  useEffect(() => {
    if (errorDeleteGeneratedDig) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Digsig Certificate",
      });
    }
  }, [errorDeleteGeneratedDig]);

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data.total_records}
      />
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "400px", height: "400px", padding: 10 }}>
          <IconButton
            sx={{ position: "absolute", top: 12, right: 12 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <QRCodeCanvas size={250} value={qrGenerated} />
        </Box>
      </Modal>
    </>
  );
};

export default GeneratedDigsigTable;
