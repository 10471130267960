import * as yup from "yup";
import { validationArraySelect, validationConfirmNewPasswordL, validationEmailField, validationNewPassword, validationNotEmpty, validationStringSelect } from "./defaultValidations";

export default yup.object({
  firstName: validationNotEmpty("First Name"),
  lastName: validationNotEmpty("Last Name"),
  email: validationEmailField("Email"),
  phoneNumber: validationNotEmpty("Phone Number"),
  password: validationNewPassword("Password"),
  confirmPassword: validationConfirmNewPasswordL("Confirm Password"),
  status: validationNotEmpty("Status"),
  accessGroup: validationStringSelect("Access Group"),
});
