import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalInfo = ({ open, handleClose }: any) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width: "50%", padding: 5 }}>
        <IconButton
          sx={{ position: "absolute", top: 12, right: 12 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="h2"
          id="child-modal-title"
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Digsig DDD Value Input Guide
        </Typography>
        <Typography id="child-modal-description" sx={{ mt: 2 }}>
          <strong>Digsig Company Name/Batch ID:</strong> The value in this field
          will be an identifier for each DDD entry that can be searched by
          grouping (multiple data). Users can input the company name or the ID
          of the uploaded batch when uploading bulk.
          <br />
          <br />
          <strong>DDD Field:</strong> This value is data selected by the user to
          determine the format of the DDD value based on the DDD field that was
          previously input.
          <br />
          <br />
          <strong>DDD Entry:</strong> This value can be filled by the user in
          the form field or in the CSV file uploaded in the bulk input option.
          This value will be the identifier for each DDD value data entered, so
          that one DDD value can be searched individually.
          <br />
          <br />
          <strong>DDD Value:</strong> This value will be the DDD value in the
          Digsig data (see document ISO_IEC_20248_2022). Filling in the DDD
          value must be in accordance with the field type that has been defined
          in the DDD fields.
          <br />
          <br />
          <strong>Input Value Type:</strong> The value in this field is used to
          determine the type of value that will be input as a DDD value. The
          types of values ​​that can be input are:
          <ul>
            <li>
              <strong>String:</strong> Example of a value that will go into the
              Backend: "Some value".
            </li>
            <li>
              <strong>Number:</strong> Example of value that will go into
              Backend: 100.
            </li>
            <li>
              <strong>Array:</strong> Examples of values ​​that will go into the
              Backend: [ "some", "intro", "value"].
            </li>
            <li>
              <strong>Json:</strong> Example of values ​​that will go into the
              Backend:
              {`"one value": "string value", "two value": 20`}.
            </li>
            <li>
              <strong>Null:</strong> Example of value that will go to Backend:
              Null
            </li>
          </ul>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ModalInfo;
