import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import confirmPassword from "../../utils/validation/confirmPassword";
import Swal from "sweetalert2";
// import { useUpdatePasswordMutation } from "../../store/feature/service/adminManagementApiSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { useDeleteKeyMutation } from "../../store/feature/service/keyApiSlice";
import { useUpdatePasswordJWTMutation, useUpdatePasswordMutation } from "../../store/feature/service/dashboard";
import { Password } from "@mui/icons-material";
import { encrypt } from "../../utils/hash/password";

const ChangePasswordJWT = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userUid = useParams();
  const [
    updatePassword,
    {
      data: updatePayload,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdatePasswordJWTMutation();
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    uid: string;
    email: string;
    password: string;
    confirmPassword: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(confirmPassword),
    defaultValues: {
      uid: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const endAdornmentConfirm = useMemo(() => {
    if (isShowConfirmPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowConfirmPass]);

  const toggleShowConfirmPassword = () => {
    setIsShowConfirmPass((prev) => !prev);
  };

  // REGION: SET FORM DATA
  useEffect(() => {
    // console.log("location ===> ", location.state);
    setValue("email", location.state);
    // reset({
    //   password: "",
    //   confirmPassword: "",
    // });
  }, [location.state]);

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = async (e: any) => {
    let updatePass = {
      adm_user_email: e.email,
      adm_user_pwd_hash: encrypt(e.password),
    };
    console.log("hahaha ===> ", e);
    Swal.fire({
      title: "Submit Confirmation",
      text: "Are you sure you want to change password?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updatePassword(updatePass);
      }
    });
  };

  if (updateSuccess) {
    Swal.fire({
      icon: "success",
      title: "Success Update Password",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Success Update Password",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    }).then(() => {
      navigate("/");
    });
  }

  if (updateError) {
    Swal.fire({
      icon: "error",
      title: "Error Update Password",
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
      text: "Error Update Password",
      didOpen: () => Swal.getConfirmButton()?.focus(),
    });
  }

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={false}
      isLoadingEvent={updateLoading}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Change Password
        </Typography>
        <InputForm
          name="email"
          label="Email"
          placeholder="Type Email"
          disabled={true}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />

        <InputForm
          name="password"
          label="New Password"
          placeholder="Type New Password"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          showEndAdornment
          sxEndAdornment={{
            backgroundColor: "inherit",
            border: "none",
            cursor: "pointer",
            width: "4rem",
          }}
          endAdornment={endAdornment}
          onClickEndAdornment={toggleShowPassword}
          type={isShowPass ? "text" : "password"}
        />

        <InputForm
          name="confirmPassword"
          label="Confirm New Password"
          placeholder="Type Confirm New Password"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          showEndAdornment
          sxEndAdornment={{
            backgroundColor: "inherit",
            border: "none",
            cursor: "pointer",
            width: "4rem",
          }}
          endAdornment={endAdornmentConfirm}
          onClickEndAdornment={toggleShowConfirmPassword}
          type={isShowConfirmPass ? "text" : "password"}
        />

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/user-account")}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default ChangePasswordJWT;
