import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import {
  useDeleteKeyMngMutation,
  useDownloadCSRMutation,
} from "../../../store/feature/service/keyApiSlice";
import { useEffect, useState } from "react";
import {
  useEksportKeyDsaMutation,
  useEksportKeyRsaMutation,
} from "../../../store/feature/service/importKeyApiSlice";
import { renderToStaticMarkup } from "react-dom/server";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const KeyMngTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const [csrName, setCsrName] = useState("");
  // const { totalConfig } = useAppSelector(
  //   (store: RootState) => store.satpasDevice
  // );
  const [deleteKey, { isSuccess, isError }] = useDeleteKeyMngMutation();
  const [
    downloadCSR,
    { data: dataCSR, isSuccess: successDownload, isError: errorDownload },
  ] = useDownloadCSRMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/key-management/detail/${params.row.uid}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDowndloadCsr = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "CSR downloaded!",
        html: `
          <p style="text-align: left;">
            You can check the download progress in your browser.
          </p>
          <p style="text-align: left;">
            After download is complete, please send to your Certificate Authority (CA)  for signing.
          </p>
        `,
        icon: "question",
        showCancelButton: false,
        confirmButtonColor: "#003c80",
        // cancelButtonColor: "#808080",
        iconHtml: renderToStaticMarkup(
          <CloudDownloadOutlinedIcon style={{ fontSize: "50px" }} />
        ),
        confirmButtonText: "Understood",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setCsrName(params.row.keyName);
          downloadCSR(params.row.uid);
        }
      });
    };
    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          {/* {params.row.keyType === "RSA" || params.row.keyType === "ECDSA" ? (
            <Button variant="contained" onClick={onExport}>
              Export
            </Button>
          ) : null} */}
          <Button variant="contained" onClick={onEdit}>
            Key Detail
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#006fbb" }}
            onClick={onDowndloadCsr}
          >
            Download CSR
          </Button>
        </Stack>
      </>
    );
  };

  // // Function to convert JSON data to CSV format
  // function jsonToCsv(json: any) {
  //   const headers = ["status_code", "message", "error_details", "data"];
  //   const rows = [];
  //   const dataRow = [
  //     json.status_code,
  //     json.message,
  //     json.error_details,
  //     json.data ? json.data.replace(/\n/g, "\\n") : "", // Escape newlines for CSV
  //   ];
  //   rows.push(dataRow.join(","));

  //   return `${headers.join(",")}\n${rows.join("\n")}`;
  // }

  // // Function to download CSV file
  // function downloadCsv(filename: string, csvContent: any) {
  //   const blob = new Blob([csvContent], { type: "text/csr;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", filename);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }

  // Function to download CSR file
  function downloadCsr(filename: string, csrContent: string) {
    const blob = new Blob([csrContent], { type: "text/plain;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${filename}.csr`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  useEffect(() => {
    if (dataCSR) {
      // Convert JSON to CSV and download the file
      console.log("data here bor", dataCSR?.data);
      // const csvContent = jsonToCsv(dataCSR?.data);
      downloadCsr(csrName, dataCSR?.data);
    }
  }, [dataCSR]);

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "keyName",
      headerName: "Key Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "createdAt",
      headerName: "Creation Date",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "dddName",
      headerName: "DDD Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data?.data);
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  const rows = dataRows.map((row: any, index: any) => ({
    id: index,
    uid: row.dsg_key_uid,
    keyName: row.dsg_key_name,
    createdAt: row.dsg_key_creation_date,
    expiryDate: row.dsg_key_expiry_date,
    dddName: row.dsg_ddd_name,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data.total_records}
      />
    </>
  );
};

export default KeyMngTable;
