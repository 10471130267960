import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  RadioForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import {
  useCreateUserAccMutation,
  useUpdateUserAccMutation,
} from "../../store/feature/service/UserAccountApiSlice";
import userAccountUpdate from "../../utils/validation/userAccountUpdate";
import { useRoleListQuery } from "../../store/feature/service/adminRole";
import pageMaster from "../../utils/validation/pageMaster";
import { useCreatePageMasterMutation, useUpdatePageMasterMutation } from "../../store/feature/service/PageMaster";

const PageMasterField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optAccGroup, setOtpAccGroup] = useState([]);

  const {
    data: dataRoleList,
    isError: errRoleList,
    isSuccess: succRoleList,
  } = useRoleListQuery({});
  const [
    CreatePageMaster,
    { data: dataCreate, isError: isErrorCreate, isSuccess: isSuccesCreate },
  ] = useCreatePageMasterMutation();

  // useUpdateDACertMutation
  const [
    UpdatePageMaster,
    {
      data: dataUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccesUpdate,
    },
  ] = useUpdatePageMasterMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    pageName: string;
    linkToPage: string;
    masterCode: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(pageMaster),
    defaultValues: {
      pageName: "",
      linkToPage: "",
      masterCode: "",
      status: "",
    },
  });

  useEffect(() => {
    if (state) {
      setValue("pageName", state.name);
      setValue("linkToPage", state.accessUrl);
      setValue("masterCode", state.code);
      setValue("status", state.status);
      console.log("haha ===> ", state);
    }
  }, [state]);

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    console.log("hahaha ===> ", e);
    let tempObj = {
      pge_master_access_url: e.linkToPage,
      pge_master_code: e.masterCode,
      pge_master_name: e.pageName,
      pge_master_status: e.status,
    };
    console.log("tempObj ===> ", tempObj);
    if (editFgroup) {
      UpdatePageMaster({ id: idParam.id, body: tempObj });
    } else {
      CreatePageMaster(tempObj);
    }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/page-master");
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "active",
    },
    {
      label: "INACTIVE",
      value: "inactive",
    },
    {
      label: "DELETED",
      value: "deleted",
    },
  ];

  const optLinkToPage = [
    {
      label: "Form Type ID",
      value: "Form Type ID",
    },
    {
      label: "URL",
      value: "URL",
    },
  ];
  useEffect(() => {
    if (dataRoleList) {
      setOtpAccGroup(
        dataRoleList?.data.map((item: any) => ({
          label: item.adm_role_name,
          value: item.adm_role_uid,
        }))
      );
    }
  }, [dataRoleList]);

  useEffect(() => {
    if (isSuccesCreate) {
      Swal.fire({
        icon: "success",
        title: "Success Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create Page Master",
      }).then(() => navigate("/page-master"));
    }
  }, [isSuccesCreate]);

  useEffect(() => {
    if (isErrorCreate) {
      Swal.fire({
        icon: "error",
        title: "Error Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Upload Page Master",
      });
    }
  }, [isErrorCreate]);

  useEffect(() => {
    if (isSuccesUpdate) {
      Swal.fire({
        icon: "success",
        title: "Success Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Page Master ",
      }).then(() => navigate("/page-master"));
    }
  }, [isSuccesUpdate]);

  useEffect(() => {
    if (isErrorUpdate) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Page Master ",
      });
    }
  }, [isErrorUpdate]);

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Typography variant="h3" fontFamily="Open Sans" mb={3}>
              {editFgroup ? "Update Page Master" : "Create Page Master"}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="pageName"
              label="Page Name"
              placeholder="Type Page Name"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="linkToPage"
              label="Link To Page"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="masterCode"
              label="Master Code"
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <SelectFormm
              name="status"
              label="Status"
              defaultValue={0}
              options={optStatusSelect}
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                type="button"
                color="gray"
                onClick={handleRevoke}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                {editFgroup ? "Update" : "Submit"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default PageMasterField;
