import IRoutes from "../interfaces/IRoutes";
import UserAccount from "../pages/userAccount/UserAccount";
// import UserAccountDetail from "../pages/userAccount/UserAccountDetail";
import UserAccountField from "../pages/userAccount/UserAccountField";

const UserAccountRoute: Array<IRoutes> = [
  {
    element: <UserAccount />,
    path: "/user-account",
  },
  {
    element: <UserAccountField />,
    path: "/user-account/create",
  },
  {
    element: <UserAccountField />,
    path: "/user-account/edit/:id",
  },
];

export { UserAccountRoute };
