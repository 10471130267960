import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./feature/api/apiSlice";
import { userReducer } from "./feature/reducers/userReducer";
import { roleReducer } from "./feature/reducers/roleReducer";

export const reducer = combineReducers({
  // satpasDevice: satpasDeviceReducer,
  role: roleReducer,
  user: userReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export type RootState = ReturnType<typeof reducer>;


export const createStore = () =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware().concat(apiSlice.middleware);
    },
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
