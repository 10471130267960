import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken, setAdminRoles, setToken } from "../../utils/storage";
// import { decodeToken } from "react-jwt";
import { instanceAdmin } from "./service/instance";
import { encrypt } from "../../utils/hash/password";

export interface ILogin {
  email: string;
  password: string;
  showPass: boolean;
}

export interface IAccess {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  uid: string;
}

export const initialAccess: IAccess = {
  create: false,
  read: false,
  update: false,
  delete: false,
  uid: ""
};

export interface ISubmitLogin {
  rejectMessage: string;
  isLoading: boolean;
  name: string;
  roleTitle: string;
  accesToken: string;
  adminRole: any;
  accessSidebar: any;
  ocspConfig: any;
}

const initialState: ISubmitLogin = {
  rejectMessage: "",
  isLoading: false,
  name: "",
  roleTitle: "",
  accesToken: getToken(),
  adminRole: {},
  accessSidebar: [],
  ocspConfig: initialAccess,
};

export const submitLogin = createAsyncThunk<any, any, { rejectValue: any }>(
  "user/submitLogin",
  async (dataToPost, { rejectWithValue, dispatch }) => {
    const { Email, Password } = dataToPost;
    try {
      let pasEnc = encrypt(Password);
      const resp = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_API_ADMIN}/user/login`,
        data: {
          Email,
          password: pasEnc,
        },
      });
      console.log(Email, pasEnc, "here isinya");
      console.log("response here ===> ", resp.data.data.access);
      const token = resp.data.data.access;
      setToken(token);
      dispatch(getRoleType({}));
      return resp.data;
    } catch (error: any) {
      throw rejectWithValue(error);
    }
  }
);

export const getRoleType = createAsyncThunk<any, any, { rejectValue: any }>(
  "auth/get-role",
  async (data, { rejectWithValue }) => {
    try {
      const resp: any = await instanceAdmin.get(`/user/auth-data/va`
      );
      
      // GET ACCESS ROLE DATA
      const accessRole = resp.data.data;

      // GET ACCESS SIDEBAR
      const accessSidebar = accessRole.admin_role_access.map((form:any) => {
         if(form.uc_adm_role_access_url != null && form.uc_adm_role_access_url != "") {
          return form.uc_adm_role_access_url.replace("/","")
        } else {
          return form.uc_frm_type_uid
        }
      })
      accessSidebar.push("dashboard")

      // GET ACCESS CRUD
      const form = accessRole.admin_role_access.find((obj: any) => obj.uc_adm_role_access_url === "/key")
      const ocspConfig: IAccess = {
        uid: (form.uc_adm_role_access_url != null && form.uc_adm_role_access_url != "")
        ? form.uc_adm_role_access_url
        : form.uc_frm_type_uid,
        create: form.uc_adm_role_acs_create === 1 ? true : false,
        read: form.uc_adm_role_acs_read === 1 ? true : false,
        update: form.uc_adm_role_acs_update === 1 ? true : false,
        delete: form.uc_adm_role_acs_delete === 1 ? true : false,
      }

      // RETURN ACCESS ROLE
      return { accessRole, accessSidebar, ocspConfig };

    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeRejectMassage: (state) => {
      state.rejectMessage = "";
    },
    removeToken: (state) => {
      state.accesToken = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.name = action.payload.name;
        state.accesToken = action.payload.data.access;
        state.roleTitle = action.payload.role_title;
      })
      .addCase(submitLogin.rejected, (state, action) => {
        state.rejectMessage = action.payload.response.data.err_details;
        state.isLoading = false;
      })
      .addCase(getRoleType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoleType.fulfilled, (state, action) => {
        const { accessRole, accessSidebar, ocspConfig } = action.payload 
        state.isLoading = false;
        state.adminRole = accessRole
        state.accessSidebar = accessSidebar
        state.ocspConfig = ocspConfig
      })
  },
});

export const { removeRejectMassage, removeToken } = counterSlice.actions;

export default counterSlice.reducer;
