// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const digsigCertApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratedDigsig: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/ddd-value/get-distinct-ddd-entry-and-value`,
          params: params,
        };
      },
      providesTags: ["GetGeneratedDigsig"],
    }),
    deleteGeneratedDigsig: builder.mutation<any, any>({
      query: (uid) => {
        return {
          url: `/ddd-value/delete-ddd-entry-and-value/${uid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeleteGeneratedDigsig", "GetGeneratedDigsig"],
    }),
    getGeneratedDigsigDetail: builder.mutation<any, any>({
      query: (uid) => {
        return {
          url: `/ddd-value/get-detail-ddd-entry-and-value/${uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetGeneratedDigsigDetail"],
    }),
    createEntryAndVal: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/ddd-value/create-ddd-entry-and-value`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateEntryAndVal"],
    }),
    updateEntryAndVal: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/ddd-value/update-ddd-value/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateEntryAndVal"],
    }),
    createGenDig: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/generated-digsig/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateGenDig", "GetGeneratedDigsig"],
    }),
    downloadGenDigsig: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/generated-digsig/get-by-entry-uid/${params}`,
          method: "GET",
        };
      },
      invalidatesTags: ["DownloadGenDigsig"],
    }),
    postGenDigsig: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/generated-digsig/generate-by-entry-uid/${params}`,
          method: "POST",
        };
      },
      invalidatesTags: ["PostGenDigsig"],
    }),
    // /generated-digsig/get-by-entry-name-and-company?entryName=fdsafas&company=fdsafaasfsa
    downloadGenDigsigEntryComp: builder.mutation<any, any>({
      query: ({ entryName, company }) => {
        return {
          url: `/generated-digsig/get-by-entry-name-and-company?entryName=${entryName}&company=${company}`,
          method: "GET",
        };
      },
      invalidatesTags: ["DownloadGenDigsigEntryComp"],
    }),

    //generated Digsig Error Log
    genDigsigErrLog: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/digsig-generated-error-info/list`,
          params,
        };
      },
      providesTags: ["GenDigsigErrLog"],
    }),
    deleteGenDigsigErrLog: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/digsig-generated-error-info/`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["DeleteGenDigsigErrLog", "GenDigsigErrLog"],
    }),
  }),
});

export const {
  useGetGeneratedDigsigQuery,
  useDeleteGeneratedDigsigMutation,
  useCreateGenDigMutation,
  useCreateEntryAndValMutation,
  usePostGenDigsigMutation,
  useUpdateEntryAndValMutation,
  useDownloadGenDigsigMutation,
  useDownloadGenDigsigEntryCompMutation,
  useGenDigsigErrLogQuery,
  useDeleteGenDigsigErrLogMutation,
  useGetGeneratedDigsigDetailMutation,
} = digsigCertApiSlice;
