import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const SpecFieldsTable = ({ data }: any) => {
  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "fieldTypeName",
      headerName: "Field Type Name",
      flex: 1,
      cellClassName: 'custom-cell', 
      minWidth: 100,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 2.5,
      minWidth: 250,
      cellClassName: 'custom-cell', 
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            overflowWrap: "anywhere",
          }}
        >
          {params.value}
        </Box>
      ),
    },
  ];

  // REGION: INIT ROWS
  console.log("data form table ===> ", data.dataFieldTypes.data);
  const dataRow =
    data?.dataFieldTypes?.data !== undefined &&
    data?.dataFieldTypes?.data !== null
      ? data?.dataFieldTypes?.data
      : [];
  const rows = dataRow?.map((row: any, index: any) => ({
    id: index,
    uid: row.dsg_ddd_uid,
    fieldTypeName: row.dsg_ddd_field_type_name,
    desc: row.dsg_ddd_field_type_notes,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "350px", width: "500px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowHeight={() => 'auto'}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-cell": {
              padding: "8px",  // Atur padding sesuai kebutuhan
            },
            "& .MuiDataGrid-columnHeader": {
              padding: "8px",  // Tambahkan padding juga di header kolom jika diperlukan
            }
          }}
        />
      </Box>
    </>
  );
};

export default SpecFieldsTable;
