import IRoutes from "../interfaces/IRoutes";
import DomainAuthorCert from "../pages/domainAuthorCert/DomainAuthorCert";
import DomainAuthorCertField from "../pages/domainAuthorCert/DomainAuthorCertField";

const DomainAuthorityCertRoute: Array<IRoutes> = [
    {
        element: <DomainAuthorCert />,
        path: "/domain-authority-certificate",
    },
    {
        element: <DomainAuthorCertField />,
        path: "/domain-authority-certificate/create",
    },
    {
        element: <DomainAuthorCertField />,
        path: "/domain-authority-certificate/edit/:id",
    }
];

export { DomainAuthorityCertRoute };
