import * as yup from "yup";
import {
  validationNotEmpty,
  validationStringSelect,
} from "./defaultValidations";

export default yup.object({
  endpointPageName: validationNotEmpty("Endpoint Page Name"),
  endpointMethod: validationNotEmpty("Endpoint Method"),
  endpointPath: validationStringSelect("Endpoint Path"),
});
