// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const keyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getKeyMng: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/digsig-key/list-dsg-keys`,
          params: params,
        };
      },
      providesTags: ["GetKeysListMng"],
    }),
    deleteKeyMng: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/key/delete`,
          method: "DELETE",
          body,
        };
      },
      invalidatesTags: ["DeleteKeyMng", "GetKeysListMng"],
    }),
    getKeyMngByUid: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/digsig-key/get-dsg-key-by-uid/${params}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetKeysListMngByUid"],
    }),
    // /digsig-key/get-dsg-key-by-uid/
    //TODO this endpoint to get detail for key management
    revokeKeyMngByUid: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/digsig-key/revoke-dsg-key/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["RevokeKeysListMngByUid", "GetKeysListMngByUid"],
    }),
    downloadCSR: builder.mutation<any, any>({
      query: (uid) => {
        return {
          url: `/digsig-key/download-csr/${uid}`,
          method: "GET",
        };
      },
      invalidatesTags: ["DownloadCSR"],
    }),
  }),
});

export const {
  useGetKeyMngQuery,
  useDeleteKeyMngMutation,
  useGetKeyMngByUidMutation,
  useRevokeKeyMngByUidMutation,
  useDownloadCSRMutation
} = keyApiSlice;
