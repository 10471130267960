import React from 'react';
import {
  Box,
  FormControl,
  SxProps,
  Theme,
  Typography,
  useTheme,
  Skeleton,
} from '@mui/material';
import { Controller } from 'react-hook-form';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

import FormLabel from '../../../atoms/formLabel/index';
import { StyledBox, StyledInput } from './elements';

interface Iprops {
  sxFC?: any;
  sxFL?: any;
  name: string;
  label?: string;
  errors?: any;
  control?: any;
  maxLength?: number;
  disabled?: boolean;
  type?: string;
  onChange?: Function;
  required?: boolean;
  placeholder?: string;
  isUpperCase?: boolean;
  specificName?: string;
  isLoading?: boolean;
  showStartAdornment?: boolean;
  showEndAdornment?: boolean;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  sxEndAdornment?: SxProps<Theme>;
  onClickEndAdornment?: Function;
}

export default function InputForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    errors,
    maxLength = 100,
    disabled,
    type = 'text',
    onChange,
    required = false,
    placeholder = '',
    isUpperCase = false,
    specificName = '',
    isLoading,
    showStartAdornment,
    showEndAdornment,
    startAdornment = <Typography variant="body16Regular">%</Typography>,
    endAdornment = <Typography variant="body16Regular">%</Typography>,
    sxEndAdornment,
    onClickEndAdornment = () => {},
  } = props;
  const { palette } = useTheme();
  return (
    <FormControl
      sx={{ flex: 1, display: 'flex', mb: '1.5rem', ...sxFC }}
      variant="standard"
    >
      {label?.length !== 0 && (
        <FormLabel htmlFor={name} sx={{ color: palette.neutral.dark, ...sxFL }}>
          {label}
          {!required && !disabled && (
            <span
              style={{
                marginLeft: '4px',
                color: palette.neutral.light,
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontStyle: 'italic',
              }}
            >
              (optional)
            </span>
          )}
          {required && label !== undefined && !disabled && (
             <span style={{color: '#ef4545'}}> *</span>
          )}
        </FormLabel>
      )}
      {!isLoading && (
        <Controller
          defaultValue=""
          control={control}
          name={name}
          render={({ field }) => (
            <StyledInput
              {...field}
              onWheel={(e: any) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              sx={
                isUpperCase
                  ? {
                      '& input': {
                        textTransform: 'uppercase',
                      },
                    }
                  : {}
              }
              disabled={disabled}
              size="small"
              id={name}
              inputProps={{
                maxLength,
                placeholder,
              }}
              autoComplete={
                type === 'password' || type === 'email' ? 'new-password' : 'off'
              }
              placeholder={placeholder}
              error={!!errors[name] || !!errors[specificName]}
              helperText={
                errors[name]?.message || errors[specificName]?.message
              }
              type={type}
              InputProps={{
                endAdornment: showEndAdornment && (
                  <Box
                    onClick={() => {
                      onClickEndAdornment();
                    }}
                    sx={{
                      background: palette.gray.light,
                      height: '40px',
                      width: '33px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: `1px solid ${palette.gray.main}`,
                      borderTopRightRadius: '4px',
                      borderBottomRightRadius: '4px',
                      ...sxEndAdornment,
                    }}
                  >
                    {endAdornment}
                  </Box>
                ),
                startAdornment: showStartAdornment && (
                  <Box
                  sx={{
                    background: palette.gray.light,
                    height: '40px',
                    width: '33px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px solid ${palette.gray.main}`,
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px',
                    ...sxEndAdornment,
                  }}>
                    {startAdornment}
                  </Box>
                ),
              }}
              onChange={(e: any) => {
                if(type === 'file') {
                  field.onChange(e.target.value);
                  onChange?.(e.target.files);
                } else {
                  if (e.target.value.length <= maxLength) {
                    field.onChange(e.target.value);
                    onChange?.(e.target.value);
                  } 
                }
              }}
            />
          )}
        />
      )}
      {isLoading && (
        <StyledBox>
          <Skeleton
            style={{
              height: '40px',
              borderRadius: '4px',
            }}
          />
        </StyledBox>
      )}
    </FormControl>
  );
}
