import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
// import {
//   getAllOcspList,
//   removeEventFlag,
// } from '../../store/feature/satpasDevice';
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import { useGetLogEventsListQuery } from "../../store/feature/service/logEventsApiSlice";
import ApplicationLogSearch from "../../components/molecules/applicationLog/ApplicationLogSearch";
import ApplicationLogTable from "../../components/molecules/applicationLog/ApplicationLogTable";

const ApplicationLog = () => {
  // const dispatch = useAppDispatch();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  // const { isLoadingOcspConfig, isLoadingEvent, eventFlag } = useAppSelector(
  //   (state: RootState) => state.satpasDevice
  // );

  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data: dataLogEvents, isLoading } = useGetLogEventsListQuery(params);

  useEffect(() => {
    console.log("hahaha ===> ", dataLogEvents);
  }, [dataLogEvents]);
  // console.log('data aja aada ', dataLogEvents)
  // console.log('data aja aada ', dataLogEvents.events)
  // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if (eventFlag) {
  // dispatch(getAllOcspList(params));
  // dispatch(removeEventFlag());
  //   }
  // }, [eventFlag]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      // isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Application Log
        </Typography>
        <ApplicationLogSearch params={params} setParams={setParams} />
        <ApplicationLogTable
          data={dataLogEvents !== undefined ? dataLogEvents : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default ApplicationLog;
