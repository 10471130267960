import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import DddFieldsTable from "../../components/molecules/dddFields/DddFieldsTable";
import DddFieldsSearch from "../../components/molecules/dddFields/DddFieldsSearch";
import {
  useGenerateKeyDddFieldsMutation,
  useGetDddFieldsListQuery,
} from "../../store/feature/service/dddFields";
// import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const DddFieldsMng = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { dddFieldM } = useAppSelector((state: RootState) => state.role);

  const { data, isLoading } = useGetDddFieldsListQuery(params);
  const [
    generateKeyField,
    {
      isSuccess: successGenerateKey,
      isError: isErrorGenerateKey,
      isLoading: loadingGenerateKey,
      data: dataGenerateKey,
      error: errorGenerateKey,
    },
  ] = useGenerateKeyDddFieldsMutation();

  // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if (dddFieldM) {
  //     console.log("dddFieldM ===> ", dddFieldM.create);
  //     console.log("role ===> ", role);
  //   }
  //   console.log("role ===> ", role);
  // }, [dddFieldM, role]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      isLoadingEvent={loadingGenerateKey}
      titleWidth={"300px"}
    >
      <Grid container>
        <Grid item xs={10} md={10} lg={10} xl={10}>
          <Typography
            variant="h3"
            fontFamily="Open Sans"
            sx={{ marginY: "20px" }}
          >
            DDD Fields
          </Typography>
        </Grid>

        <Grid
          item
          xs={2}
          md={2}
          lg={2}
          xl={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {dddFieldM.create &&(
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/ddd-fields/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Create
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <DddFieldsSearch params={params} setParams={setParams} />
          <DddFieldsTable
            data={data !== undefined ? data?.data : []}
            params={params}
            setParams={setParams}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            generateKeyField={generateKeyField}
            successGenerateKey={successGenerateKey}
            isErrorGenerateKey={isErrorGenerateKey}
          />
        </Grid>
      </Grid>
    </ListDataSkeleton>
  );
};

export default DddFieldsMng;
