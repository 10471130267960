// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const companyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompListUid: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/company/list-uid-names`,
          params: params,
        };
      },
      providesTags: ["GetCompListUid"],
    }),
  }),
});

export const { useGetCompListUidQuery } = companyApiSlice;
