// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const logEventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDddFieldsList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/ddd-field/list-all-ddd`,
          params: params,
        };
      },
      providesTags: ["GetDddFieldsList"],
    }),
    getDddFieldsTypeList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/ddd-field/list-all-field-types`,
          params: params,
        };
      },
      providesTags: ["GetDddFieldsTypeList"],
    }),
    getDddNameList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/ddd-field/list-ddd-names`,
          params: params,
        };
      },
      providesTags: ["GetDddFieldsTypeList"],
    }),
    getDddFieldByUid: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/ddd-field/detail-ddd-by-uid/${params}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetDddFieldByUid"],
    }),
    createDddFields: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/ddd-field/insert-ddd`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateDddFields", "GetDddFieldsList"],
    }),
    updateDddFields: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/ddd-field/update-ddd-by-uid/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateDddFields", "GetDddFieldsList"],
    }),
    deleteDddFields: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/ddd-field/delete-ddd-by-uid/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeleteDddFields", "GetDddFieldsList"],
    }),
    generateKeyDddFields: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/digsig-key/create-by-ddd-uid/${id}`,
          method: "POST",
        };
      },
      invalidatesTags: ["GenerateKeyDddFields"],
    }),
  }),
});

export const {
  useGetDddFieldsListQuery,
  useGetDddFieldsTypeListQuery,
  useCreateDddFieldsMutation,
  useGetDddFieldByUidMutation,
  useUpdateDddFieldsMutation,
  useDeleteDddFieldsMutation,
  useGenerateKeyDddFieldsMutation,
  useGetDddNameListQuery
} = logEventsApiSlice;
