import * as yup from "yup";
import { validationNotEmpty } from "./defaultValidations";

export default yup.object({
  domainAutCert: validationNotEmpty("Domain Authority Certificate Name"),
  company: validationNotEmpty("Company"),
  certCN: validationNotEmpty("Certificate Common Name"),
  certSN: validationNotEmpty("Certificate Serial Number"),
  uploadCert: validationNotEmpty("Upload DA/Root Certificate"),
  certLvl: validationNotEmpty("Certificate Level"),
  status: validationNotEmpty("Status"),
});
