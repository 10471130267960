// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const UserAccountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    userAccList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/user-admin/list`,
          params: params,
        };
      },
      providesTags: ["UserAccList"],
    }),
    getUserAccRole: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/user-admin/user-role-by-email`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetUserAccRole"],
    }),
    createUserAcc: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/user-admin/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateUserAcc", "UserAccList"],
    }),
    updateUserAcc: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/user-admin/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateUserAcc", "UserAccList"],
    }),
    deleteUserAcc: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/user-admin/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeleteUserAcc", "UserAccList"],
    }),
  }),
});

export const {
  useUserAccListQuery,
  useCreateUserAccMutation,
  useGetUserAccRoleMutation,
  useUpdateUserAccMutation,
  useDeleteUserAccMutation,
} = UserAccountApiSlice;
