import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
// import {
//   getAllOcspList,
//   removeEventFlag,
// } from '../../store/feature/satpasDevice';
import KeySearch from '../../components/molecules/keyMng/KeyMngSearch';
import KeyTable from '../../components/molecules/keyMng/KeyMngTable';
import ListDataSkeleton from '../../components/template/skeleton/ListDataSkeleton';
import AddIcon from '@mui/icons-material/Add';
import { useGetKeyMngQuery } from '../../store/feature/service/keyApiSlice';
// import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const KeyMng = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});

  const { data, isLoading } = useGetKeyMngQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(data){
      console.log('data baru ===> ', data)
    }
  }, [data]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      // isLoading={isLoading}
      // isLoadingEvent={isLoadingEvent}
      titleWidth={'300px'}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: '20px' }}
        >
          Key
        </Typography>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/key-management/create')}
          sx={{ marginBottom: '18px' }}
        >
          <AddIcon /> Create
        </Button> */}
        <KeySearch params={params} setParams={setParams} />
        <KeyTable
          data={data !== undefined && data !== null ? data?.data : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default KeyMng;
