import {
  FormLabel,
  Grid,
  IconButton,
  Typography,
  Popover,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect, useState } from "react";
import SpecFieldsTable from "../../components/molecules/dddFields/SpecFieldsTable";

const SpecInfoLabel = (dataFieldTypes: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleInfo = (event: any) => {
    // console.log('info brooo ===> ')
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log("dataFieldTypes ===> ", dataFieldTypes);
  });
  return (
    <>
      <Grid item lg={2}></Grid>
      <Grid item lg={2}>
        <FormLabel>
          Fields Spec. Name{" "}
          <IconButton
            aria-describedby={id}
            onClick={(e) => handleInfo(e)}
            style={{ padding: 0, color: "black", paddingLeft: "7px" }}
          >
            <InfoIcon sx={{ height: "18px", width: "18px" }} />
          </IconButton>
        </FormLabel>
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ border: "1px solid #9a9a9a", p: 2 }}>
          <Typography sx={{ p: 2 }}>
            This is the reference value to be inputted in the <br /> field
            specification name based on ISO/IEC 20248
          </Typography>
          <SpecFieldsTable data={dataFieldTypes} />
        </Box>
      </Popover>
      <Grid item lg={2}>
        <FormLabel>Fields Spec. Definition</FormLabel>
      </Grid>
      <Grid item lg={2}>
        <FormLabel>Fields Spec. Order</FormLabel>
      </Grid>
      <Grid item lg={1}></Grid>
    </>
  );
};

export default SpecInfoLabel;
