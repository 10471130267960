// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const adminRoleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    roleList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/role/list-non-pagination`,
          params: params,
        };
      },
      providesTags: ["RoleList"],
    }),
    roleAccessList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/role/list`,
          params: params,
        };
      },
      providesTags: ["RoleAccessList"],
    }),
    //   getDACertByUid: builder.query<any, any>({
    //     query: (params) => {
    //       return {
    //         url: `/da-certificate/detail/${params}`,
    //         method: "GET",
    //       };
    //     },
    //     providesTags: ["GetDACertByUid"],
    //   }),
    createAdmRole: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/role/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreateAdmRole", "RoleAccessList"],
    }),
    updateAdmRole: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/role/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateAdmRole"],
    }),
  }),
});

export const {
  useRoleListQuery,
  useRoleAccessListQuery,
  useCreateAdmRoleMutation,
  // useGetDACertByUidQuery,
  useUpdateAdmRoleMutation,
} = adminRoleApiSlice;
