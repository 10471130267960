import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useDeleteKeyMngMutation } from "../../../store/feature/service/keyApiSlice";
import { useEffect, useState } from "react";
import { useDeletePageMasterEndpointMutation } from "../../../store/feature/service/PageMasterEndpoint";

const PageMasterEndpointTable = ({ data, setParams, setUpdate }: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const [pemName, setPemName] = useState("");
  const [deletePageMaster, { isSuccess, isError }] =
  useDeletePageMasterEndpointMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // navigate(`/page-master/edit/${params.row.uid}`, { state: params.row });
      setUpdate(params.row)
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deletePageMaster(params.row.endpointUid);
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "pageName",
      headerName: "Page Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "method",
      headerName: "Method",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "endpointPath",
      headerName: "Endpoint Path",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      setParams((prev: any)=> !prev)
      Swal.fire({
        icon: "success",
        title: "Success Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Endpoint",
      })
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Endpoint",
      });
    }
  }, [isError]);
  // REGION: INIT ROWS
  console.log("data form table ===> ", data?.data);
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  const rows = dataRows.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    uid: row.pge_master_uid,
    endpointUid: row.adm_endpoint_page_uid,
    pageName: row.adm_endpoint_page_name,
    method: row.adm_endpoint_page_method,
    endpointPath: row.adm_endpoint_page_endpoint_path,
  }));

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "400px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          
        />
      </Box>
    </>
  );
};

export default PageMasterEndpointTable;
