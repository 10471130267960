import IRoutes from "../interfaces/IRoutes";
import PageMaster from "../pages/pageMaster/PageMaster";
import PageMasterEndpoint from "../pages/pageMaster/PageMasterEndpoint";
import PageMasterField from "../pages/pageMaster/PageMasterField";

const PageMasterRoute: Array<IRoutes> = [
  {
    element: <PageMaster />,
    path: "/page-master",
  },
  {
    element: <PageMasterField />,
    path: "/page-master/create",
  },
  {
    element: <PageMasterField />,
    path: "/page-master/edit/:id",
  },
  {
    element: <PageMasterEndpoint />,
    path: "/page-master/endpoint/:id",
  },
];

export { PageMasterRoute };
