// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const PageAccessApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    pageAccessList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/page-access/list`,
          params: params,
        };
      },
      providesTags: ["PageAccessList"],
    }),
    getPageAccsByUid: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/page-access/detail-by-role-uid/${params}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetPageAccsByUid"],
    }),
    createPageAccess: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/page-access/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["createPageAccess", "PageAccessList"],
    }),
      updatePageAccess: builder.mutation<any, any>({
        query: ({ id, body }) => {
          return {
            url: `/page-access/update-by-role-uid/${id}`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: ["UpdatePageAccess", "PageAccessList"],
      }),
    //   //  page-master/delete/
    //   deletePageMaster: builder.mutation<any, any>({
    //     query: (id) => {
    //       return {
    //         url: `/page-master/delete/${id}`,
    //         method: "DELETE",
    //       };
    //     },
    //     invalidatesTags: ["DeletePageMaster", "PageMasterList"],
    //   }),
  }),
});

export const {
  usePageAccessListQuery,
  useCreatePageAccessMutation,
  useGetPageAccsByUidMutation,
  useUpdatePageAccessMutation,
  // useDeletePageMasterMutation,
} = PageAccessApiSlice;
