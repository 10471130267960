// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const importKeyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getImportKey: builder.query<any, any>({
            query: params => {
                return {
                    url: `/importref/list`,
                    params: params
                }
            },
            providesTags:['GetImportKeysList'],
        }),
        deleteImportKey: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/key/delete`,
                    method: 'DELETE',
                    body
                })
            },
            invalidatesTags:['DeleteImportKey', 'GetImportKeysList']
        }),
        eksportKeyRsa: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/exportrsa`,
                    method: 'POST',
                    body
                })
            },
            invalidatesTags:['ExportRsa']
        }),
        eksportKeyDsa: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/exportecdsa`,
                    method: 'POST',
                    body
                })
            },
            invalidatesTags:['ExportEcdsa']
        }),
        importKeyRsa: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/importrsa`,
                    method: 'POST',
                    body
                })
            },
            invalidatesTags:['ImportRsa']
        }),
        importKeyDsa: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/importecdsa`,
                    method: 'POST',
                    body
                })
            },
            invalidatesTags:['ImportEcdsa']
        }),
  })
})

export const {
useGetImportKeyQuery,
useDeleteImportKeyMutation,
useEksportKeyDsaMutation,
useEksportKeyRsaMutation,
useImportKeyDsaMutation,
useImportKeyRsaMutation
} = importKeyApiSlice;