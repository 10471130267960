import { createSlice } from "@reduxjs/toolkit";
import { UserAccountApiSlice } from "../service/UserAccountApiSlice";

export interface IAccess {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  uid: string;
}

const initialAccess: IAccess = {
  create: false,
  read: false,
  update: false,
  delete: false,
  uid: "",
};

export interface IRole {
  role: IAccess[];
  [key: string]: any;
}

const initialState: IRole = {
  role: [],
  dashboard: {},
  dddFieldM: {},
  keyMng: {},
  domainAuthCert: {},
  digsigCert: {},
  digsigData: {}, // Generated Digsig
  genDigErrLog: {}, // Generated Error Log
  keyUsrLog: {},
  appLog: {},
  usrAcc: {},
  pgeMas: {},
  pgeAccs: {},
};

const transformInput = (input: any) => ({
  read: !!input.adm_pge_role_read,
  create: !!input.adm_pge_role_create,
  update: !!input.adm_pge_role_update,
  delete: !!input.adm_pge_role_delete,
  url: input.pge_master_access_url,
});

const setPageAccessState = (state: IRole, dataRole: any, key: string, urlFragment: string) => {
  const foundObj = dataRole.find((obj: any) =>
    obj.pge_master_access_url.includes(urlFragment)
  );
  if (foundObj) {
    state[key] = transformInput(foundObj);
  }
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      UserAccountApiSlice.endpoints.getUserAccRole.matchFulfilled,
      (state, action) => {
        const dataRole = action.payload.data.role_access;
        state.role = dataRole;

        const pages = [
          { key: "dddFieldM", urlFragment: "ddd-fields" },
          { key: "keyMng", urlFragment: "key-management" },
          { key: "domainAuthCert", urlFragment: "domain-authority-certificate" },
          { key: "digsigCert", urlFragment: "digsig-certificate" },
          { key: "digsigData", urlFragment: "digsig-data" },
          { key: "genDigErrLog", urlFragment: "generated-error-log" },
          { key: "keyUsrLog", urlFragment: "sign-history" },
          { key: "appLog", urlFragment: "events" },
          { key: "usrAcc", urlFragment: "user-account" },
          { key: "pgeMas", urlFragment: "page-master" },
          { key: "pgeAccs", urlFragment: "page-access" },
        ];

        pages.forEach(({ key, urlFragment }) => {
          setPageAccessState(state, dataRole, key, urlFragment);
        });
      }
    );
  },
});

export const roleReducer = roleSlice.reducer;
