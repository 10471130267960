// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const dddParamDefApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDddParamDefUid: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/ddd-paramdef/get-param-def-by-ddd-uid/${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetDddParamDefUid"],
    }),
    downloadGenCSV: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/gen-csv/${id}`,
          method: "GET",
        };
      },
      invalidatesTags: ["DownloadGenCSV"],
    }),
    // getKeyMngByUid: builder.mutation<any, any>({
    //   query: (params) => {
    //     return {
    //       url: `/digsig-key/get-dsg-key-by-uid/${params}`,
    //       method: "GET",
    //     };
    //   },
    //   invalidatesTags: ["GetKeysListMngByUid"],
    // }),
    // /digsig-key/get-dsg-key-by-uid/
    //TODO this endpoint to get detail for key management
  }),
});

export const {
  useGetDddParamDefUidMutation,
  useDownloadGenCSVMutation,
} = dddParamDefApiSlice;
