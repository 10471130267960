import IRoutes from "../interfaces/IRoutes";
import KeyMngField from "../pages/keyMng/KeyMngField";
import KeyMng from "../pages/keyMng/KeyMng";

const ImportKeyMngRoute: Array<IRoutes> = [
    {
        element: <KeyMng />,
        path: "/key-management",
    },
    {
        element: <KeyMngField />,
        path: "/key-management/detail/:id",
    },
    {
        element: <KeyMngField />,
        path: "/key-management/edit/:id",
    }
];

export { ImportKeyMngRoute };
