import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import PageAccessSearch from "../../components/molecules/pageAccess/PageAccessSearch";
import PageAccessTable from "../../components/molecules/pageAccess/PageAccessTable";
import { useRoleAccessListQuery } from "../../store/feature/service/adminRole";
const PageAccess = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pgeAccs } = useAppSelector((state: RootState) => state.role);
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});

  const { data, isLoading } = useRoleAccessListQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (data) {
      console.log("data baru role ===> ", data);
    }
  }, [data]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      // isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Grid container>
        <Grid item xs={10} md={10} lg={10} xl={10}>
          <Typography
            variant="h3"
            fontFamily="Open Sans"
            sx={{ marginY: "20px" }}
          >
            Page Access
          </Typography>
        </Grid>

        {pgeAccs.create && (
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            xl={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/page-access/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Create
            </Button>
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <PageAccessSearch params={params} setParams={setParams} />
          <PageAccessTable
            data={data !== undefined && data !== null ? data?.data : []}
            params={params}
            setParams={setParams}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
          />
        </Grid>
      </Grid>
    </ListDataSkeleton>
  );
};

export default PageAccess;
