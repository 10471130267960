import { Bar } from "react-chartjs-2";

const BarChart = () => {
  const labels = ["Jun", "Jul", "Aug", "Sept", "Okt", "Nov", "Des"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Value 1",
        data: [20, 30, 25, 40, 35, 50, 70],
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Value 2",
        data: [10, 20, 15, 30, 25, 40, 80],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };
  return (
    <Bar
      datasetIdKey="id"
      data={data}
      options={{
        indexAxis: "x",
        plugins: {
          legend: {
            position: "right",
          },
        },
      }}
    />
  );
};

export default BarChart;
