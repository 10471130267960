import * as yup from "yup";
import { validationNotEmpty, validationTextFieldNoSymbol, validationTextFieldNoSymbolAllowSpace } from "./defaultValidations";

export default yup.object({
  name: validationNotEmpty("Name"),
  specVer: validationNotEmpty("Specificication Version"),
  dauri: validationNotEmpty("DAURI"),
  daid: validationTextFieldNoSymbolAllowSpace("DAID", 3, 9),
  cid: validationNotEmpty("CID"),
  verURI: validationNotEmpty("Verification URI"),
  subOrg: validationNotEmpty("Subject Organization"),
  subCntry: validationNotEmpty("Subject Country"),
});
