import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { SelectFormm } from "../forms";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BarChart from "../chart/BarChart";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePickerForm from "../forms/datePickerForm";

const DailyStatics = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const staticsPath = pathname.includes("statistics");
  const [sxGrid, setSxGrid] = useState({ xsDaily: 6, xsSelect: 4 });
  const [userActive, setUserActive] = useState(""); // TODO: NEED PARAMETER FROM API
  const schema = yup.object({
    title: yup.string().required(),
  });
  useEffect(() => {
    if (staticsPath) {
      setSxGrid({
        xsDaily: 12,
        xsSelect: 3,
      });
    }
  }, []);

  const {
    control,
    formState: { errors },
  } = useForm<{
    deviceId: string;
    hostname: string;
    enrolledStart: string;
    enrolledEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      deviceId: "",
      hostname: "",
      enrolledStart: "",
      enrolledEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Liveness Result",
      value: "Liveness Result",
    },
    {
      label: "Device",
      value: "Device",
    },
  ];

  const optTime = [
    {
      label: "Hourly",
      value: "Hourly",
    },
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Device",
      value: "Device",
    },
  ];

  const handleStatusSelect = (e: any) => {
    setUserActive(e.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={sxGrid.xsDaily}>
        <Typography variant="h1">
          {!staticsPath ? "Daily Statics" : "Statics"}
        </Typography>
      </Grid>
      <Grid item xs={sxGrid.xsSelect}>
        <SelectFormm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
          isClear
        />
      </Grid>
      {staticsPath && (
        <>
          <Grid item xs={3}>
            <SelectFormm
              name="status"
              defaultValue={1}
              options={optTime}
              placeholder="Please Select"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
              sxFL={{ backgroundColor: "red" }}
              onChangeForm={handleStatusSelect}
              isClear
            />
          </Grid>
          <Grid item xs={3}>
            <DatePickerForm
              name="date"
              defaultValue={null}
              control={control}
              errors={errors}
              placeholder="Start Date"
              disabled={false}
              isLoading={false}
              required
            />
          </Grid>
          <Grid item xs={3}>
          <DatePickerForm
              name="date"
              defaultValue={null}
              control={control}
              errors={errors}
              placeholder="Start Date"
              disabled={false}
              isLoading={false}
              required
            />
          </Grid>
        </>
      )}
      {!staticsPath && (
        <Grid item xs={2}>
          <Button
            variant="contained"
            sx={{ mt: 1.1 }}
            onClick={() => navigate("/statistics")}
          >
            Detail
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <BarChart />
      </Grid>
    </Grid>
  );
};

export default DailyStatics;
