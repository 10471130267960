import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import userAccount from "../../utils/validation/userAccount";
import {
  useCreateUserAccMutation,
  useUpdateUserAccMutation,
} from "../../store/feature/service/UserAccountApiSlice";
import userAccountUpdate from "../../utils/validation/userAccountUpdate";
import { useRoleListQuery } from "../../store/feature/service/adminRole";
import { encrypt } from "../../utils/hash/password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const UserAccountField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optAccGroup, setOtpAccGroup] = useState([]);
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false)

  const {
    data: dataRoleList,
    isError: errRoleList,
    isSuccess: succRoleList,
  } = useRoleListQuery({});
  const [
    CreateUserAcc,
    {
      data: dataCreateDACert,
      isError: isErrorCreateUserAcc,
      isSuccess: isSuccesCreateUserAcc,
    },
  ] = useCreateUserAccMutation();

  // useUpdateDACertMutation
  const [
    UpdateUserAcc,
    {
      data: dataUpdateUserAcc,
      isError: isErrorUpdateUserAcc,
      isSuccess: isSuccesUpdateUserAcc,
    },
  ] = useUpdateUserAccMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    status: string;
    accessGroup: string;
    roleUid: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(editFgroup ? userAccountUpdate : userAccount),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      status: "",
      accessGroup: "",
      roleUid: "",
    },
  });

  useEffect(() => {
    if (state) {
      console.log("data ===> ", state);
      // const data = state?.data[0];
      setValue("firstName", state.firstName);
      setValue("lastName", state.lastName);
      setValue("email", state.email);
      setValue("phoneNumber", state.phoneNumber);
      setValue("accessGroup", state.accessGroup);
      setValue("status", state.status);
    }
  }, [state]);

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    console.log("hahaha ===> ", e);
    let tempObj = {
      adm_role_uid: e.accessGroup,
      adm_user_email: e.email,
      adm_user_firstname: e.firstName,
      adm_user_lastname: e.lastName,
      adm_user_mobile: e.phoneNumber,
      adm_user_pwd_hash: encrypt(e.password),
      adm_user_status: e.status,
    };
    let updateObj = {
      adm_role_uid: e.accessGroup,
      adm_user_email: e.email,
      adm_user_firstname: e.firstName,
      adm_user_lastname: e.lastName,
      adm_user_mobile: e.phoneNumber,
      adm_user_status: e.status,
    };
    console.log("tempObj ===> ", tempObj);
    if (editFgroup) {
      UpdateUserAcc({ id: idParam.id, body: updateObj });
    } else {
      CreateUserAcc(tempObj);
    }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/user-account");
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "active",
    },
    {
      label: "INACTIVE",
      value: "inactive",
    },
  ];

  useEffect(() => {
    if (dataRoleList) {
      setOtpAccGroup(
        dataRoleList?.data.map((item: any) => ({
          label: item.adm_role_name,
          value: item.adm_role_uid,
        }))
      );
    }
  }, [dataRoleList]);

  useEffect(() => {
    if (isSuccesCreateUserAcc) {
      Swal.fire({
        icon: "success",
        title: "Success Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create User Account",
      }).then(() => navigate("/user-account"));
    }
  }, [isSuccesCreateUserAcc]);

  useEffect(() => {
    if (isErrorCreateUserAcc) {
      Swal.fire({
        icon: "error",
        title: "Error Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Upload Domain Authority Certificate",
      });
    }
  }, [isErrorCreateUserAcc]);

  useEffect(() => {
    if (isSuccesUpdateUserAcc) {
      Swal.fire({
        icon: "success",
        title: "Success Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update User Account ",
      }).then(() => navigate("/user-account"));
    }
  }, [isSuccesUpdateUserAcc]);

  useEffect(() => {
    if (isErrorUpdateUserAcc) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update User Account ",
      });
    }
  }, [isErrorUpdateUserAcc]);

  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };

  const endAdornmentConfirm = useMemo(() => {
    if (isShowConfirmPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowConfirmPass]);

  const toggleShowConfirmPassword = () => {
    setIsShowConfirmPass((prev) => !prev);
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Typography variant="h3" fontFamily="Open Sans" mb={3}>
              {editFgroup ? "Update User Account" : "Create User Account"}
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <InputForm
              name="firstName"
              label="First Name"
              placeholder="Type First Name"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={6}>
            <InputForm
              name="lastName"
              label="Last Name"
              placeholder="Type Last Name"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="email"
              label="Email"
              placeholder="Type Email"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <InputForm
              name="phoneNumber"
              label="Phone Number"
              placeholder="Type Phone Number"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              required
              type="number"
            />
          </Grid>
          {!editFgroup && (
            <>
              <Grid item lg={12}>
                <InputForm
                  name="password"
                  label="Password"
                  placeholder="Type Password"
                  disabled={editFgroup}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  maxLength={500}
                  required
                  showEndAdornment
                  sxEndAdornment={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    width: "4rem",
                  }}
                  endAdornment={endAdornment}
                  onClickEndAdornment={toggleShowPassword}
                  type={isShowPass ? "text" : "password"}
                />
              </Grid>
              <Grid item lg={12}>
                <InputForm
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Type Confirm Password"
                  disabled={editFgroup}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  maxLength={500}
                  required
                  showEndAdornment
                  sxEndAdornment={{
                    backgroundColor: "inherit",
                    border: "none",
                    cursor: "pointer",
                    width: "4rem",
                  }}
                  endAdornment={endAdornmentConfirm}
                  onClickEndAdornment={toggleShowConfirmPassword}
                  type={isShowConfirmPass ? "text" : "password"}
                />
              </Grid>
            </>
          )}{" "}
          <Grid item lg={12}>
            <SelectFormm
              name="status"
              label="Status"
              defaultValue={0}
              options={optStatusSelect}
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <SelectFormm
              name="accessGroup"
              label="Access Group"
              defaultValue={0}
              options={optAccGroup}
              placeholder="none"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item lg={12}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                type="button"
                color="gray"
                onClick={handleRevoke}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                {editFgroup ? "Update" : "Submit"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default UserAccountField;
