import IRoutes from "../interfaces/IRoutes";
import ApplicationLog from "../pages/log/applicationLog";
import LogEvents from "../pages/log/KeyUsageLog";

const LogRoute: Array<IRoutes> = [
    {
        element: <LogEvents />,
        path: "/sign-history",
    },
    {
        element: <ApplicationLog />,
        path: "/events",
    }, 
];

export { LogRoute };
