import Swal from "sweetalert2";

const showAlert = (type: 'success' | 'error', title: string, text: string) => {
    Swal.fire({
      icon: type,
      title,
      text,
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#051438",
  });
};

export default showAlert;
