// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const PageMasterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    pageMasterList: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/page-master/list`,
          params: params,
        };
      },
      providesTags: ["PageMasterList"],
    }),
    //   getDACertByUid: builder.query<any, any>({
    //     query: (params) => {
    //       return {
    //         url: `/da-certificate/detail/${params}`,
    //         method: "GET",
    //       };
    //     },
    //     providesTags: ["GetDACertByUid"],
    //   }),
    createPageMaster: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/page-master/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreatePageMaster", "PageMasterList"],
    }),
    updatePageMaster: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/page-master/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdatePageMaster", "PageMasterList"],
    }),
    //  page-master/delete/
    deletePageMaster: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/page-master/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeletePageMaster", "PageMasterList"],
    }),
    pageMasterListNonPage: builder.query<any, any>({
      query: (id) => {
        return {
          url: `/page-master/list-non-pagination`,
          method: "GET",
        };
      },
      providesTags: ["PageMasterListNonPage"],
    }),
  }),
});

export const {
  usePageMasterListQuery,
  useCreatePageMasterMutation,
  useUpdatePageMasterMutation,
  useDeletePageMasterMutation,
  usePageMasterListNonPageQuery,
} = PageMasterApiSlice;
