import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const KeyUsageLogSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [keyName, setKeyName] = useState("");
  const [certCN, setCertCN] = useState("");
  const [createdStart, setCreatedStart] = useState("");
  const [createdEnd, setCreatedEnd] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    keyName: string;
    certCN: string;
    severity: string;
    type: string;
    dateStart: string;
    dateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      keyName: "",
      certCN: "",
      severity: "",
      type: "",
      dateStart: "",
      dateEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Low",
      value: "LOW",
    },
    {
      label: "Medium",
      value: "MEDIUM",
    },
    {
      label: "High",
      value: "HIGH",
    },
  ];

  const handleKeyName = (e: string) => {
    setKeyName(e);
  };
  const handleCertCN = (e: any) => {
    setCertCN(e);
  };

  const handleDateStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(createdEnd))) {
        setAllDate(NewDate);
      } else {
        setCreatedStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const handleDateEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setCreatedEnd(NewDate);
  };

  const setAllDate = (date: string) => {
    reset({
      keyName: keyName,
      certCN: certCN,
      dateStart: date,
      dateEnd: date,
    });
    setCreatedStart(date);
    setCreatedEnd(date);
  };
  const conditionCheck = () => {
    if (createdStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "blue",
        text: "Please select Created Start",
      });
      reset({
        keyName: keyName,
        certCN: certCN,
        dateStart: "",
        dateEnd: "",
      });
      setCreatedEnd("");
      return false;
    } else {
      return true;
    }
  };

  useDebouncedEffect(() => setSearchFilterDebounced(keyName), [keyName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(certCN), [certCN], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdStart),
    [createdStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdEnd),
    [createdEnd],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      dsg_key_name: keyName,
      dsg_certificate_common_name: certCN,
      created_from: createdStart,
      created_to: createdEnd,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="keyName"
          errors={errors}
          control={control}
          required
          placeholder="Key Name"
          maxLength={100}
          isLoading={false}
          onChange={handleKeyName}
        />
        <InputForm
          name="certCN"
          errors={errors}
          control={control}
          required
          placeholder="Certificate Common Name"
          maxLength={100}
          isLoading={false}
          onChange={handleCertCN}
        />
        <DatePickerForm
          name="dateStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Start History Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateStart}
        />
        <DatePickerForm
          name="dateEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="End History Date"
          disabled={false}
          isLoading={false}
          required
          onChange={handleDateEnd}
        />
      </Box>
    </Box>
  );
};

export default KeyUsageLogSearch;
