import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import GeneratedErrorLogTable from "../../components/molecules/applicationLog/GeneratedErrorLogTable";
import { useGenDigsigErrLogQuery } from "../../store/feature/service/generateDigsigApiSlice";

const GeneratedErrorLog = () => {
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});

  const { data: dataLogEvents, isLoading } = useGenDigsigErrLogQuery(params);

  useEffect(() => {
    console.log("hahaha ===> ", dataLogEvents?.data?.data);
  }, [dataLogEvents]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      // isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Generated Error Log
        </Typography>
        {/* <GeneratedErrorLogSearch params={params} setParams={setParams} /> */}
        <GeneratedErrorLogTable
          data={dataLogEvents !== undefined ? dataLogEvents : []}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default GeneratedErrorLog;
