// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const digsigCertApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDigsigCert: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/digsig-certficate/list-certificates`,
          params: params,
        };
      },
      providesTags: ["GetDACert"],
    }),
    getDigsigUidNames: builder.query<any, any>({
      query: (params) => {
        return {
          url: `/digsig-key/get-dsg-keys-uid-and-names`,
          params: params,
        };
      },
      providesTags: ["GetDigsigUidNames"],
    }),
    uploadCertDig: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/digsig-certficate/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["UploadCertDig", "GetDACert"],
    }),
    updateCertDig: builder.mutation<any, any>({
      query: ({id, body}) => {
        return {
          url: `/digsig-certficate/update-certificate/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdateCertDig", "GetDACert"],
    }),
    deleteCertDig: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `digsig-certficate/delete-certificate/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeleteCertDig", "GetDACert"],
    }),
    getCertByUid: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/digsig-certficate/get-certificate-by-uid/${params}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetCertByUid"],
    }),
  }),
});

export const {
  useGetDigsigCertQuery,
  useGetDigsigUidNamesQuery,
  useUploadCertDigMutation,
  useDeleteCertDigMutation,
  useGetCertByUidMutation,
  useUpdateCertDigMutation
} = digsigCertApiSlice;
