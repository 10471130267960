import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  RadioForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { useGetKeyMngByUidMutation } from "../../store/feature/service/keyApiSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import domainAuthorCert from "../../utils/validation/domainAuthorCert";
import { useGetCompListUidQuery } from "../../store/feature/service/companyApiSlice";
import {
  useCreateDACertMutation,
  useGetDACertByUidQuery,
  useUpdateDACertMutation,
} from "../../store/feature/service/domainAuthorCertApiSlice";
import domainAuthorCertUpdate from "../../utils/validation/domainAuthorCertUpdate";

const DomainAuthorCertField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optComp, setOtpComp] = useState([]);
  const [cert, setCert] = useState("");

  // const [getKeyMngByUid, { data, isError, isSuccess }] =
  //   useGetKeyMngByUidMutation();
  // useGetDACertByUidMutation
  const {
    data: dataDACertUid,
    isError: errDACertUid,
    isSuccess: succDACertUid,
  } = useGetDACertByUidQuery(idParam.id);
  const [
    CreateDACert,
    {
      data: dataCreateDACert,
      isError: isErrorCreateDACert,
      isSuccess: isSuccesCreateDACert,
    },
  ] = useCreateDACertMutation();
  const {
    data: dataGetCompListUid,
    isError: errGetCompListUid,
    isSuccess: successGetCompListUid,
  } = useGetCompListUidQuery({});

  // useUpdateDACertMutation
  const [
    UpdateDACert,
    {
      data: dataUpdateDACert,
      isError: isErrorUpdateDACert,
      isSuccess: isSuccesUpdateDACert,
    },
  ] = useUpdateDACertMutation();
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    domainAutCert: string;
    company: string;
    certCN: string;
    certSN: string;
    uploadCert: string;
    certLvl: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(
      editFgroup ? domainAuthorCertUpdate : domainAuthorCert
    ),
    defaultValues: {
      domainAutCert: "",
      company: "",
      certCN: "",
      certSN: "",
      uploadCert: "",
      certLvl: "",
      status: "",
    },
  });

  useEffect(() => {
    if (dataDACertUid?.data) {
      console.log("data ===> ", dataDACertUid);
      const keyData = dataDACertUid?.data;
      setValue("domainAutCert", keyData.dsg_da_root_certificate_name);
      setValue("company", keyData.cpn_uid);
      setValue("certCN", keyData.dsg_da_root_certificate_cn);
      setValue("certSN", keyData.dsg_da_root_certificate_serial_number);
      // setValue("uploadCert", keyData.dsg_key_revoke_reason);
      setValue("certLvl", keyData.dsg_da_root_certificate_level);
      setValue("status", keyData.dsg_da_root_certificate_status);
    }
  }, [dataDACertUid]);

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    console.log("sudah sudah muak ===> ", e);
    console.log("sudah upload ===> ", cert);

    let tempObj = {
      cpn_uid: e.company,
      dsg_da_root_certificate_cn: e.certCN,
      dsg_da_root_certificate_data: cert,
      dsg_da_root_certificate_level: e.certLvl,
      dsg_da_root_certificate_name: e.domainAutCert,
      dsg_da_root_certificate_serial_number: e.certSN,
      dsg_da_root_certificate_status: e.status,
    };
    let updateObj = {
      dsg_da_root_certificate_level: e.certLvl,
      dsg_da_root_certificate_name: e.domainAutCert,
      dsg_da_root_certificate_status: e.status,
    };
    if (editFgroup) {
      UpdateDACert({ id: idParam.id, body: updateObj });
    } else {
      CreateDACert(tempObj);
    }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/domain-authority-certificate");
      }
    });
  };

  useEffect(() => {
    if (dataGetCompListUid) {
      setOtpComp(
        dataGetCompListUid?.data.map((item: any) => ({
          label: item.cpn_name,
          value: item.cpn_uid,
        }))
      );
    }
  }, [dataGetCompListUid]);

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  const handleDestroy = (e: any) => {
    console.log("handle revoke ===> ");
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "active",
    },
    {
      label: "INACTIVE",
      value: "inactive",
    },
  ];
  const optCertLvlSelect = [
    {
      label: "Domain Authority (DA)",
      value: "da",
    },
    {
      label: "Intermediary",
      value: "intermediary",
    },
    {
      label: "Root",
      value: "root",
    },
  ];

  const handleUploadCert = (e: any) => {
    const file = e[0];
    console.log("file here ", file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(",")[1];
        if (base64String) {
          // setValue("uploadCert", base64String); // Set the Base64 string to the form field
          setCert(base64String);
          console.log("Base64 String: ", base64String); // Optional: log the Base64 string for debugging
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  useEffect(() => {
    if (isSuccesCreateDACert) {
      Swal.fire({
        icon: "success",
        title: "Success Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Upload Domain Authority Certificate",
      }).then(() => navigate("/domain-authority-certificate"));
    }
  }, [isSuccesCreateDACert]);

  useEffect(() => {
    if (isErrorCreateDACert) {
      Swal.fire({
        icon: "error",
        title: "Error Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Upload Domain Authority Certificate",
      });
    }
  }, [isErrorCreateDACert]);

  useEffect(() => {
    if (isSuccesUpdateDACert) {
      Swal.fire({
        icon: "success",
        title: "Success Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Upload Domain Authority Certificate",
      }).then(() => navigate("/domain-authority-certificate"));
    }
  }, [isSuccesUpdateDACert]);

  useEffect(() => {
    if (isErrorUpdateDACert) {
      Swal.fire({
        icon: "error",
        title: "Error Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Upload Domain Authority Certificate",
      });
    }
  }, [isErrorUpdateDACert]);

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup
            ? "Update Domain Authority Certificate"
            : "Upload Domain Authority Certificate"}
        </Typography>
        <InputForm
          name="domainAutCert"
          label="Domain Authority Certificate Name"
          placeholder="Type Domain Authority Certificate Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <SelectFormm
          name="company"
          label="Company"
          defaultValue={0}
          options={optComp}
          placeholder="none"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <InputForm
          name="certCN"
          label="Certificate Common Name"
          placeholder="Type Certificate Common Name"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="certSN"
          label="Certificate Serial Number"
          placeholder="Type Certificate Serial Number"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="uploadCert"
          label="Upload DA/Root Certificate"
          placeholder="Type Upload DA/Root Certificate"
          disabled={editFgroup}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          onChange={handleUploadCert}
          required
          type="file"
        />
        <RadioForm
          name="certLvl"
          label="Certificate Level"
          defaultValue={0}
          options={optCertLvlSelect}
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          vertical
        />
        <SelectFormm
          name="status"
          label="Status"
          defaultValue={0}
          options={optStatusSelect}
          placeholder="none"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            type="button"
            color="gray"
            onClick={handleRevoke}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            {editFgroup ? "Update" : "Upload"}
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default DomainAuthorCertField;
