import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import GeneratedDigsigSearch from "../../components/molecules/generatedDigsig/GeneratedDigsigSearch";
import GeneratedDigsigTable from "../../components/molecules/generatedDigsig/GeneratedDigsigTable";
import { useGetGeneratedDigsigQuery } from "../../store/feature/service/generateDigsigApiSlice";
// import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const GeneratedDigsig = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { digsigData } = useAppSelector((state: RootState) => state.role);
  // const data: any = []
  // REGION: DISPATCH OCSP ALL
  // useEffect(()=> {
  //   dispatch(getAllOcspList(params))
  // },[params])

  const { data, isLoading } = useGetGeneratedDigsigQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (data) {
      console.log("data baru ===> ", data);
    }
  }, [data]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      // isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Grid container>
        <Grid item xs={7} md={7} lg={7} xl={7}>
          <Typography
            variant="h3"
            fontFamily="Open Sans"
            sx={{ marginY: "20px" }}
          >
            Generated Digsig
          </Typography>
        </Grid>

        <Grid
          item
          xs={5}
          md={5}
          lg={5}
          xl={5}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            variant="contained"
            color="warning"
            onClick={() => navigate("/digsig-data/download")}
            sx={{ marginBottom: "18px" }}
          >
            Download generated Digsig
          </Button>
          {digsigData.create && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/digsig-data/upload")}
                sx={{ marginBottom: "18px", marginLeft: "20px" }}
              >
                Bulk Upload
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/digsig-data/create")}
                sx={{ marginBottom: "18px", marginLeft: "20px" }}
              >
                Create
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GeneratedDigsigSearch params={params} setParams={setParams} />
          <GeneratedDigsigTable
            data={data !== undefined && data !== null ? data?.data : []}
            params={params}
            setParams={setParams}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
          />
        </Grid>
      </Grid>
    </ListDataSkeleton>
  );
};

export default GeneratedDigsig;
