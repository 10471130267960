import IRoutes from "../interfaces/IRoutes";
import DigsigCert from "../pages/digsigCert/DigsigCert";
import DigsigCertField from "../pages/digsigCert/DigsigCertField";

const DigsigCertRoute: Array<IRoutes> = [
    {
        element: <DigsigCert />,
        path: "/digsig-certificate",
    },
    {
        element: <DigsigCertField />,
        path: "/digsig-certificate/create",
    },
    {
        element: <DigsigCertField />,
        path: "/digsig-certificate/edit/:id",
    }
];

export { DigsigCertRoute };
