import { Box, Button, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
// import { deleteOcspConfiguration } from '../../../store/feature/satpasDevice';
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import {
  useDeleteImportKeyMutation,
  useEksportKeyDsaMutation,
  useEksportKeyRsaMutation,
} from "../../../store/feature/service/importKeyApiSlice";
import { useEffect, useState } from "react";

const ImportKeyTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  // const { totalConfig } = useAppSelector(
  //   (store: RootState) => store.satpasDevice
  // );
  const [pemName, setPemName] = useState("");
  const [deleteKey, { isSuccess, isError }] = useDeleteImportKeyMutation();
  const [
    eksportDsa,
    {
      isSuccess: successDsa,
      isError: isErrorDsa,
      data: dataDsa,
      error: errorDsa,
    },
  ] = useEksportKeyDsaMutation();
  const [
    eksportRsa,
    {
      isSuccess: successRsa,
      isError: isErrorRsa,
      data: dataRsa,
      error: errorRsa,
    },
  ] = useEksportKeyRsaMutation();

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: NAVIGATE TO EDIT
    const onExport = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      if (params.row.keyType === "RSA") {
        eksportRsa({ kid: params.row.kid });
        const arrName = params.row.kid.split("/");
        setPemName(arrName[arrName.length - 1]);
        console.log("rusak RSA ==> ");
      } else {
        eksportDsa({ kid: params.row.kid });
        const arrName = params.row.kid.split("/");
        setPemName(arrName[arrName.length - 1]);
      }
    };

    const onCopyID = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigator.clipboard.writeText(params.row.kid);
      Swal.fire({
        icon: "success",
        title: "Succes Copy Key ID",
        showConfirmButton: false,
        text: "Succes Copy Key ID to Clipboard",
        timer: 1000
      });
    }

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteKey({
            kid: params.row.kid,
          });
        }
        console.log("kid ===> ", {
          kid: params.row.kid,
        });
      });
    };

    // REGION: RETURN TSX
    return (
      <>
        <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onCopyID} color="success">
            Copy Key ID
          </Button>
          <Button variant="contained" onClick={onExport}>
            Export
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </>
    );
  };

  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "keyType",
      headerName: "Key Type",
      flex: 1,
      minWidth: 250
    },
    // {
    //   field: "length",
    //   headerName: "Length",
    //   flex: 1,
    //   minWidth: 250,
    // },
    {
      field: "createdAt",
      headerName: "Imported At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  // REGION: INIT ROWS
  // console.log("data form table ===> ", data);
  const dataRow = data?.import_ref !== undefined ? data?.import_ref : [];
  const rows = dataRow?.map((row: any, index: any) => ({
    // uid: row.uc_ocsp_config_uid,
    id: index,
    name: row.wsop_import_key_ref_label_name,
    keyType: row.wsop_import_key_ref_type,
    createdAt: row.wsop_import_key_ref_created_at,
    kid: row.wsop_import_key_ref_kid,
    // appName: row.sworldAppname,
    // curved: row.curve
  }));

  useEffect(() => {
    if (successDsa) {
      Swal.fire({
        icon: "success",
        title: "Export Public Key Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Your ECDSA Public Key is being downloaded!",
      });
    }
  }, [successDsa]);

  useEffect(() => {
    if (successRsa) {
      Swal.fire({
        icon: "success",
        title: "Export Public Key Success",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Your RSA Public Key is being downloaded!",
      });
    }
  }, [successRsa]);

  useEffect(() => {
    if (errorRsa) {
      const errSwal = errorRsa !== undefined ? errorRsa : 'error on converting ecdsa pem to jwk, details = WSOP Error:';
      Swal.fire({
        icon: "error",
        title: "Error Download Certificate",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "error on converting ecdsa pem to jwk, details = WSOP Error:",
      });
    }
  }, [isErrorDsa, isErrorRsa]);

  useEffect(() => {
    console.log("hahaha rsa ==> ", dataRsa);
    if (dataRsa) {
      const url = window.URL.createObjectURL(new Blob([dataRsa.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", pemName+".pem");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [dataRsa]);

  useEffect(() => {
    if (dataDsa) {
      const url = window.URL.createObjectURL(new Blob([dataDsa.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", pemName+".pem");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    console.log("hahaha dsa ==> ");
  }, [dataDsa]);

  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={data?.total_records}
      />
    </>
  );
};

export default ImportKeyTable;
