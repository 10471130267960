import * as yup from 'yup';
import {
  validationEmailField,
  validationTextField,
} from './defaultValidations';

export default yup.object().shape({
  Email: validationTextField('Email/Username', 5, 127),
  Password: validationTextField('Password', 2, 100),
});
