import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDeleteGeneratedDigsigMutation } from "../../../store/feature/service/generateDigsigApiSlice";
import { useEffect, useState } from "react";
import "./GeneratedDigsigTable.css"; // Import CSS for custom styles

const GenDigsigUpdtCreateTable = ({ detail, data, setDataParam }: any) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const [
    deleteGeneratedDig,
    { isSuccess: successDeleteGeneratedDig, isError: errorDeleteGeneratedDig },
  ] = useDeleteGeneratedDigsigMutation();

  // Define columns
  const columns: GridColDef[] = [
    {
      field: "fieldParamFor",
      headerName: "Field Parameter For",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
      cellClassName: "editable-cell",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.id);
        const prevRow: any = rows[index - 1];
        if (
          index > 0 &&
          prevRow &&
          prevRow.fieldParamFor === params.row.fieldParamFor
        ) {
          return ""; // If the previous row has the same value, render nothing
        }
        return params.value; // Otherwise, show the value
      },
    },
    {
      field: "fieldSpecDefinition",
      headerName: "Field Specification",
      flex: 1,
      minWidth: 250,
      cellClassName: "editable-cell",
    },
    {
      field: "enterVal",
      headerName: "Enter Value",
      flex: 1,
      minWidth: 250,
      editable: true, // Make it editable
      cellClassName: (params: GridCellParams) =>
        isCellEditable(params) ? "" : "editable-cell", // Conditionally add class
    },
    {
      field: "inptValType",
      headerName: "Value Type (Editable)",
      flex: 1,
      minWidth: 250,
      type: "singleSelect",
      editable: true,
      valueOptions: ["no select", "string", "number", "array", "json", "null"],
      cellClassName: (params: GridCellParams) =>
        isCellEditable(params) ? "" : "editable-cell", // Conditionally add class
    },
  ];

  // Map data into rows
  const dataRows =
    data?.data !== null && data?.data !== undefined ? data?.data : [];
  // console.log('dataRows ===> ', dataRows)

  const mappedRows = dataRows.flatMap((row: any, index: any) => {
    const prevRow = dataRows[index - 1];

    return row.field_spec_arr.map((spec: any, specIndex: any) => {
      const isEditable =
        !detail &&
        specIndex === 0 &&
        !(
          index > 0 &&
          prevRow?.dsg_ddd_field_param_definition_label ===
            row.dsg_ddd_field_param_definition_label
        );

      return {
        id: `${index}-${specIndex}`,
        uid: row.dsg_ddd_uid,
        fieldParamFor:
        specIndex === 0 ? row.dsg_ddd_field_param_definition_label : "",
        paramUid: row.dsg_ddd_field_param_definition_uid,
        valUid: row.dsg_ddd_value_uid,
        fieldSpecDefinition: `${spec.dsg_ddd_param_fieldspec_name} = ${spec.dsg_ddd_param_fieldspec_definition}`,
        enterVal: specIndex === 0 ? row.dsg_ddd_value_data : "",
        inptValType: specIndex === 0 ? row.dsg_ddd_value_type : "",
        isEditable, // Only editable for specIndex === 0
      };
    });
  });

  useEffect(() => {
    setRows(mappedRows);
  }, [data]);

  const handleCellEditCommit = (params: any) => {
    const updatedRows: any = rows.map((row: any) =>
      row.id === params.id ? { ...row, [params.field]: params.value } : row
    );
    setRows(updatedRows);
    console.log(updatedRows);
    const result = updatedRows.reduce((acc: any, item: any) => {
      if (item.isEditable) {
        const existing = acc.find(
          (entry: any) => entry.fieldParamFor === item.fieldParamFor
        );
        console.log("item ===> ", item);
        if (!existing) {
          acc.push({
            fieldParamFor: item.fieldParamFor,
            inputValType: item.inptValType,
            paramUid: item.paramUid,
            enterVal: item.enterVal,
            valUid: item.valUid
          });
        }
      }
      return acc;
    }, []);
    setDataParam(result);
  };

  useEffect(() => {
    if (successDeleteGeneratedDig) {
      Swal.fire({
        icon: "success",
        title: "Success Delete",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Delete Digsig Certificate",
      }).then(() => navigate("/digsig-certificate"));
    }
  }, [successDeleteGeneratedDig]);

  useEffect(() => {
    if (errorDeleteGeneratedDig) {
      Swal.fire({
        icon: "error",
        title: "Error Delete",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Delete Digsig Certificate",
      });
    }
  }, [errorDeleteGeneratedDig]);

  const isCellEditable = (params: GridCellParams) => {
    const row: any = rows.find((row: any) => row.id === params.id);
    return row?.isEditable || false;
  };

  return (
    <>
      <Box sx={{ height: "300px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          onCellEditCommit={handleCellEditCommit}
          isCellEditable={isCellEditable}
          getRowHeight={() => "auto"}
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '16px',  // Adjust the padding as needed
            },
          }}
        />
      </Box>
    </>
  );
};

export default GenDigsigUpdtCreateTable;
