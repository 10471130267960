import { apiSlice } from '../api/apiSlice'

  export const livenessDeviceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDashboardInfo: builder.mutation<any, any>({
            query: params => {
                return {
                    url: `/group/user`,
                    params: params
                }
            },
            invalidatesTags:['GetDashboardInfo'],
        }), 
        updatePassword: builder.mutation<any, any>({
            query: (body) => {
            //   const encryptedOldPassword = encrypt(oldPassword);
            //   const encryptedNewPassword = encrypt(newPassword);
              return {
                url: `/user-admin/change-password`,
                method: "PUT",
                body
              }
            },
            invalidatesTags: ['UpdatePassword']
          }),
          updatePasswordJWT: builder.mutation<any, any>({
            query: (body) => {
            //   const encryptedOldPassword = encrypt(oldPassword);
            //   const encryptedNewPassword = encrypt(newPassword);
              return {
                url: `/user-admin/change-password-with-jwt`,
                method: "PUT",
                body
              }
            },
            invalidatesTags: ['UpdatePassword']
          }),
          logout: builder.mutation<any, any>({
            query: body => {
                return ({
                    url: `/logout`,
                    method: 'POST'
                })
            },
            invalidatesTags:['logoutUser']
        }),
  })
})

export const {
useGetDashboardInfoMutation, 
useUpdatePasswordMutation,
useUpdatePasswordJWTMutation,
useLogoutMutation
} = livenessDeviceApiSlice;
