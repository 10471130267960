import { ISidebar } from "../interfaces/ISidebar";
import DvrIcon from "@mui/icons-material/Dvr";
import HomeIcon from "@mui/icons-material/Home";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import ArticleIcon from "@mui/icons-material/Article";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Person2Icon from '@mui/icons-material/Person2';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ErrorIcon from '@mui/icons-material/Error';

export const arrSidebar: Array<ISidebar> = [
  {
    key: "dashboard",
    name: "Home",
    path: "/",
    icon: <HomeIcon />,
  },
  {
    key: "ddd-fields",
    name: "DDD Fields",
    path: "/ddd-fields",
    icon: <InsertDriveFileIcon />,
  },
  {
    key: "key-management",
    name: "Key Management",
    path: "/key-management",
    icon: <KeyIcon />,
  },
  {
    key: "certificate",
    name: "Certificate",
    path: "",
    icon: <ArticleIcon />,
    children: [
      {
        key: "domain-authority-certificate",
        name: "Domain Authority Certificate",
        path: "/domain-authority-certificate",
        icon: "",
      },
      {
        key: "digsig-certificate",
        name: "Digsig Certificate",
        path: "/digsig-certificate",
        icon: "",
      },
    ],
  },
  {
    key: "digsig-data",
    name: "Generated DIGSIG",
    path: "/digsig-data",
    icon: <DriveFileRenameOutlineIcon />,
  },
  {
    key: "generated-error-log",
    name: "Generated Error Log",
    path: "/generated-error-log",
    icon: <ErrorIcon />,
  },
  {
    key: "log",
    name: "Log",
    path: "",
    icon: <DvrIcon />,
    children: [
      {
        key: "sign-history",
        name: "Key Usage Log",
        path: "/sign-history",
        // path: "/log-events",
        icon: "",
      },
      {
        key: "application-log",
        name: "Application Log",
        path: "/events",
        icon: "",
      },
    ],
  },
  // {
  //   key: "key",
  //   name: "Key",
  //   path: "/key",
  //   icon: <FeaturedPlayListIcon />,
  // },
  // {
  //   key: "import-key",
  //   name: "Import Public Key",
  //   path: "/import-key",
  //   icon: <DnsIcon />,
  // },
  {
    key: "account-and-permission",
    name: "Account And Permission",
    path: "",
    icon: <SupervisorAccountIcon />,
    children: [
      {
        key: "user-account",
        name: "User Account",
        path: "/user-account",
        icon: "",
      },
      {
        key: "page-master",
        name: "Page Master",
        path: "/page-master",
        icon: "",
      },
      {
        key: "page-access",
        name: "Page Access",
        path: "/page-access",
        icon: "",
      },
    ],
  },
  {
    key: "change-password",
    name: "Change Password",
    path: "/change-password",
    icon: <Person2Icon />,
  },
  {
    key: "logout",
    name: "Logout",
    path: "/logout",
    icon: <LogoutIcon />,
  },
];
