import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import Swal from "sweetalert2";
import dddFields from "../../utils/validation/dddFields";
import {
  useCreateDddFieldsMutation,
  useGetDddFieldByUidMutation,
  useUpdateDddFieldsMutation,
} from "../../store/feature/service/dddFields";

const DddFieldsMngField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");

  const [fileContent, setFileContent] = useState("");

  const [
    createDddFields,
    {
      isSuccess: successCreateDdd,
      isError: isErrorCreateDdd,
      isLoading: loadingCreateDdd,
    },
  ] = useCreateDddFieldsMutation();
  const [
    updateDddFields,
    {
      isSuccess: successUpdateDdd,
      isError: isErrorUpdateDdd,
      isLoading: loadingUpdateDdd,
    },
  ] = useUpdateDddFieldsMutation();
  const [
    getDddFieldByUid,
    {
      isSuccess: successDddFieldByUid,
      isError: isErrorDddFieldByUid,
      data: dataDddFieldByUid,
    },
  ] = useGetDddFieldByUidMutation();

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: "";
    specVer: string;
    dauri: string;
    daid: string;
    cid: string;
    verURI: string;
    subOrg: string;
    subCntry: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(dddFields),
    defaultValues: {
      name: "",
      specVer: "",
      dauri: "",
      daid: "",
      cid: "",
      verURI: "",
      subOrg: "",
      subCntry: "",
    },
  });

  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    console.log("saiki ===> ", e);
    // if (e.keyType === "ECDSA") {
    //   importDsa({ label: e.name, pem: fileContent });
    // } else {
    //   importRsa({ label: e.name, pem: fileContent });
    // }

    let tempCreate = {
      dsg_ddd_cid: Number(e.cid),
      dsg_ddd_daid: e.daid,
      dsg_ddd_dauri: e.dauri,
      dsg_ddd_name: e.name,
      dsg_ddd_revocationuri: "string",
      dsg_ddd_spec_version: e.specVer,
      dsg_ddd_subject_country: e.subCntry,
      dsg_ddd_subject_organization: e.subOrg,
      dsg_ddd_verificationuri: e.verURI,
    };
    if (editFgroup) {
      updateDddFields({ id: idParam, body: tempCreate });
    } else {
      createDddFields(tempCreate);
    }
  };

  useEffect(() => {
    if (editFgroup) {
      getDddFieldByUid(idParam);
      console.log("location ==> ", location);
      console.log("useparams ==> ", idParam);
    }
  }, [editFgroup]);

  useEffect(() => {
    if (successDddFieldByUid) {
      const data = dataDddFieldByUid?.data;
      console.log("data ==> ", data);
      reset({
        name: data.dsg_ddd_name,
        specVer: data.dsg_ddd_spec_version,
        dauri: data.dsg_ddd_dauri,
        daid: data.dsg_ddd_daid,
        cid: data.dsg_ddd_cid,
        verURI: data.dsg_ddd_verificationuri,
        subOrg: data.dsg_ddd_subject_organization,
        subCntry: data.dsg_ddd_subject_country,
      });
    }
  }, [successDddFieldByUid]);

  useEffect(() => {
    if (successCreateDdd) {
      Swal.fire({
        icon: "success",
        title: "Success Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create DDD Fields",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => navigate("/ddd-fields"));
    }
  }, [successCreateDdd]);

  useEffect(() => {
    if (successUpdateDdd) {
      Swal.fire({
        icon: "success",
        title: "Success Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update DDD Fields",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      }).then(() => navigate("/ddd-fields"));
    }
  }, [successUpdateDdd]);

  useEffect(() => {
    if (isErrorCreateDdd) {
      Swal.fire({
        icon: "error",
        title: "Error Create",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Create DDD Fields",
      });
    }
  }, [isErrorCreateDdd]);

  useEffect(() => {
    if (isErrorUpdateDdd) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update DDD Fields",
      });
    }
  }, [isErrorUpdateDdd]);

  const handleCancel = (e: any) => {
    Swal.fire({
      title: "Your change won't be saved",
      text: "We won't be able to save your data if you move away from this page",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Discard data",
      cancelButtonText: "Go back",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/ddd-fields");
      }
    });
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      isLoading={false}
      isLoadingEvent={loadingCreateDdd || loadingUpdateDdd}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup ? "Update DDD Fields" : "Create DDD Fields"}
        </Typography>
        <InputForm
          name="name"
          label="DDD Name"
          placeholder="type DDD Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="specVer"
          label="Specificication Version"
          placeholder="type Specificication Version"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="dauri"
          label="DAURI"
          placeholder="type Dauri"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="daid"
          label="DAID"
          placeholder="type Dauri"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="cid"
          label="CID"
          placeholder="type CID"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="number"
        />
        <InputForm
          name="verURI"
          label="Verification URI"
          placeholder="type Verification URI"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="subOrg"
          label="Subject Organization"
          placeholder="type Subject Organization"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="subCntry"
          label="Subject Country"
          placeholder="Type Subject Country"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {/* <InputForm
          name="pemFile"
          label="Upload PEM File"
          placeholder="keyName"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
          type="file"
          onChange={handleFileChange}
        /> */}
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button variant="contained" color="gray" onClick={handleCancel}>
            Cancel
          </Button>
          {editFgroup && (
            <Button variant="contained" type="submit">
              Update
            </Button>
          )}
          {!editFgroup && (
            <Button variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default DddFieldsMngField;
