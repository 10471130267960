import { LOCAL_STORAGE_KEY  } from '../constants'

const { TOKEN, REFRESH_TOKEN ,ADMIN_ROLE } = LOCAL_STORAGE_KEY;

export function setToken(token: string) {
  // console.log("local storage called ======> ", token);
  return localStorage.setItem(TOKEN, token);
}

export function setRefreshToken(refresh_token: string) {
  // console.log("local storage called ======> ", token);
  return localStorage.setItem(REFRESH_TOKEN, refresh_token);
}

export function setAdminRoles(role: any) {
  // console.log("set admin roles local storage ======> ", role);
  return localStorage.setItem(ADMIN_ROLE, role);
}

export function getToken() {
  // console.log("token from getToken ==> ", localStorage.getItem(TOKEN));
  return localStorage.getItem(TOKEN) || "";
}

export function getRefreshToken() {
  // console.log("token from getToken ==> ", localStorage.getItem(TOKEN));
  return localStorage.getItem(REFRESH_TOKEN) || "";
}

export function getAdminRoles() {
  return localStorage.getItem(ADMIN_ROLE);
}

export function clearStorage() {
  localStorage.removeItem(TOKEN);
}