import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import { IOpenMainProps } from "../../../interfaces/IUtils";
import { useNavigate } from "react-router-dom";
import { arrSidebar } from "../../../constants/SidebarRouteName";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useEffect, useState } from "react";
import korlantasLogo from "../../../assets/logo/korlantas-logo.png";
import { ISidebar } from "../../../interfaces/ISidebar";
import React from "react";

// Icons
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { clearStorage } from "../../../utils/storage";
import { removeToken } from "../../../store/feature/user";

const Sidebar = ({ open, setOpen }: IOpenMainProps) => {
  const drawerWidth = 320;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [selected, setSelected] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedChild, setSelectedChild] = useState(-1);
  const [filteredBar, setFilteredBar] = useState<any>([])
  const [expandedItemIndex, setExpandedItemIndex] = useState<number | null>(
    null
  );
  const { role } = useAppSelector((state: RootState) => state.role);
  const bar: any = role.filter((item: any) => item.adm_pge_role_read === 1);

  const mergeBar = [
    // {
    //     "adm_pge_role_read": 1,
    //     "adm_pge_role_create": 1,
    //     "adm_pge_role_update": 1,
    //     "adm_pge_role_delete": 1,
    //     "pge_master_name": "Change Password",
    //     "pge_master_access_url": "/change-password"
    // },
    {
        "adm_pge_role_read": 1,
        "adm_pge_role_create": 1,
        "adm_pge_role_update": 1,
        "adm_pge_role_delete": 1,
        "pge_master_name": "Loguot",
        "pge_master_access_url": "/logout"
    }
]
const barConcat = mergeBar.concat(bar)
  // console.log("arrsidebar ==> ", JSON.stringify(arrSidebar));

  const handleDrawerClose = () => {
    setOpen(false);
  };
  function hasPathAccess(item: any, accessArray: any) {
    // console.log('item ===> ', accessArray)
    return accessArray.some(
      (access: any) => access.pge_master_access_url === item.path
    );
  }
  useEffect(() => {
    if (barConcat && arrSidebar) {
      // Memfilter arr berdasarkan accessArray
      // console.log('barConcat ===> ', barConcat)

      // Memfilter arr berdasarkan path
      let filteredArr = arrSidebar
        .map((item: any) => {
          // console.log('arr ===> ', item)
          if (item.children) {
            // Filter children berdasarkan path
            let filteredChildren = item.children.filter((child: any) =>
              hasPathAccess(child, barConcat)
            );
            // Hanya mengembalikan parent jika ada children yang lolos filter
            return filteredChildren.length > 0
              ? { ...item, children: filteredChildren }
              : null;
          } else {
            return hasPathAccess(item, barConcat) ? item : null;
          }
        })
        .filter((item: any) => item !== null); // Hapus item yang null
        setFilteredBar(filteredArr);
    }
    // console.log('barConcat ===> ', role)
  }, [arrSidebar, role]);

  // Retrieve the selected index on component mount
  useEffect(() => {
    const savedIndex = localStorage.getItem("selectedSidebarIndex");
    if (savedIndex) {
      const index = parseInt(savedIndex, 10);
      setSelected(index);
      setExpandedItemIndex(index);
    }
  }, []);

  const openedMixin = () => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = () => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const handleSelectedClick = (path: any, index: number) => {
    setSelected(selected === index ? -1 : index);
    setExpandedItemIndex(expandedItemIndex === index ? null : index);

    // Save selected index to localStorage
    localStorage.setItem("selectedSidebarIndex", index.toString());

    if (path.length) {
      if (path === "/logout") {
        dispatch(removeToken());
        navigate("/login", { replace: true });
        clearStorage();
      } else {
        navigate(path);
        localStorage.removeItem("page");
        localStorage.removeItem("pagesize");
      }
    } else {
      setSelectedChild(-1);
    }
  };

  const handleSelectedChild = (index: number) => {
    setSelectedChild(index);
    setAnchorEl(null);
  };

  const handleNavigate = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const openPop = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderChildren = (children?: ISidebar[]) => {
    if (!children) return null;

    return children.map(
      ({ key, name, path, icon, children: subChildren }, index) => {
        return (
          <ListItem
            key={key}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate(path)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "initial",
                px: 4,
                color:
                  selectedChild === index
                    ? theme.palette.white.main
                    : theme.palette.white.darker,
              }}
              selected={selectedChild === index}
              onClick={() => handleSelectedChild(index)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                  color:
                    selectedChild === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        );
      }
    );
  };

  const renderChildrenPopover = (children?: ISidebar[]) => {
    if (!children) return null;
    return children.map(
      ({ key, name, path, icon, children: subChildren }, index) => {
        return (
          <ListItem
            key={key}
            disablePadding
            sx={{ display: "block", bgcolor: theme.palette.primary.main }}
            onClick={() => navigate(path)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "initial",
                px: 4,
                color:
                  selectedChild === index
                    ? theme.palette.white.main
                    : theme.palette.white.darker,
              }}
              selected={selectedChild === index}
              onClick={() => handleSelectedChild(index)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: "center",
                  color:
                    selectedChild === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </ListItem>
        );
      }
    );
  };

  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
          ...openedMixin(),
          "& .MuiDrawer-paper": openedMixin(),
        }),
        ...(!open && {
          ...closedMixin(),
          "& .MuiDrawer-paper": closedMixin(),
        }),
      }}
      variant="permanent"
    >
      <Box
        sx={{
          backgroundColor: `${theme.palette.primary.main}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
        }}
      >
        {open && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: theme.palette.white.darker }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        )}
      </Box>
      <List>
        {filteredBar.map(
          ({ key, name, path, icon, children }: ISidebar, index: any) => (
            <React.Fragment key={key}>
              <ListItem
                key={key}
                disablePadding
                sx={{ display: "block" }}
                onClick={handleNavigate}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    color:
                      selected === index
                        ? theme.palette.white.main
                        : theme.palette.white.darker,
                  }}
                  onClick={() => handleSelectedClick(path, index)}
                  selected={selected === index}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:
                        selected === index
                          ? theme.palette.white.main
                          : theme.palette.white.darker,
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    sx={{
                      opacity: open ? 1 : 0,
                    }}
                  />
                  {open &&
                    children &&
                    (expandedItemIndex === index ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronRightIcon />
                    ))}
                </ListItemButton>
              </ListItem>
              {selected === index && open && renderChildren(children)}
              {selected === index && !open && (
                <Popover
                  id="popover"
                  open={openPop}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                >
                  {renderChildrenPopover(children)}
                </Popover>
              )}
            </React.Fragment>
          )
        )}
      </List>
    </MuiDrawer>
  );
};

export default Sidebar;
