// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from "../api/apiSlice";

export const PageMasterEndpointApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // pageMasterList: builder.query<any, any>({
    //   query: (params) => {
    //     return {
    //       url: `/page-master/list`,
    //       params: params,
    //     };
    //   },
    //   providesTags: ["PageMasterList"],
    // }),
    getPageMastrEndpntByUid: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: `/endpoint-page/list?pge_master_name=${params}`,
          method: "GET",
        };
      },
      invalidatesTags: ["GetPageMastrEndpntByUid"],
    }),
    createPageMasterEndpoint: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/endpoint-page/create`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["CreatePageMasterEndpoint", "GetPageMastrEndpntByUid"],
    }),
    updatePageMasterEndpoint: builder.mutation<any, any>({
      query: ({ id, body }) => {
        return {
          url: `/endpoint-page/update/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["UpdatePageMasterEndpoint", "GetPageMastrEndpntByUid"],
    }),
    // //  page-master/delete/
    deletePageMasterEndpoint: builder.mutation<any, any>({
      query: (id) => {
        return {
          url: `/endpoint-page/delete/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DeletePageMasterEndpoint", "GetPageMastrEndpntByUid"],
    }),
  }),
});

export const {
  useGetPageMastrEndpntByUidMutation,
  useCreatePageMasterEndpointMutation,
  useUpdatePageMasterEndpointMutation,
  useDeletePageMasterEndpointMutation,
} = PageMasterEndpointApiSlice;
