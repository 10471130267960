import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";
import DomainAuthorCertTable from "../../components/molecules/domainAuthorCert/DomainAuthorCertTable";
import DomainAuthorCertSearch from "../../components/molecules/domainAuthorCert/DomainAuthorCertSearch";
import { useGetDACertQuery } from "../../store/feature/service/domainAuthorCertApiSlice";
// import { useGetAllOcspListQuery } from '../../store/feature/service/satpasDeviceApiSlice';

const DomainAuthorCert = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { domainAuthCert } = useAppSelector((state: RootState) => state.role);

  const { data, isLoading } = useGetDACertQuery(params);

  // REGION: DISPATCH SUCCESS EVENT
  // useEffect(() => {
  //   if (role) {
  //     console.log("role baru ===> ", role);
  //   }
  // }, [role]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoading}
      // isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Grid container>
        <Grid item xs={10} md={10} lg={10} xl={10}>
          <Typography
            variant="h3"
            fontFamily="Open Sans"
            sx={{ marginY: "20px" }}
          >
            Domain Authority Certificate
          </Typography>
        </Grid>

        <Grid
          item
          xs={2}
          md={2}
          lg={2}
          xl={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {domainAuthCert.create && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/domain-authority-certificate/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Upload
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <DomainAuthorCertSearch params={params} setParams={setParams} />
          <DomainAuthorCertTable
            data={data !== undefined && data !== null ? data?.data : []}
            params={params}
            setParams={setParams}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
          />
        </Grid>
      </Grid>
    </ListDataSkeleton>
  );
};

export default DomainAuthorCert;
