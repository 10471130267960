import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormLabel, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import dddFields from "../../utils/validation/dddFields";
import {
  useGetDddFieldsTypeListQuery,
  useUpdateDddFieldsMutation,
} from "../../store/feature/service/dddFields";
import {
  useCreateParamDefMutation,
  useCreateParamSpecMutation,
  useDeleteParamDefMutation,
  useDeleteParamSpecMutation,
  useGetParamDefUIDQuery,
  useUpdateParamDefMutation,
  useUpdateParamSpecMutation,
} from "../../store/feature/service/paramDef";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import WarningIcon from "@mui/icons-material/Warning";
import { renderToStaticMarkup } from "react-dom/server";
import SpecInfoLabel from "./SpecInfoLabel";
import showAlert from "../../utils/showAlert";

const DddFieldsMngDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: idParam } = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("edit");

  const [fields, setFields] = useState([{ paramLabel: "", paramOrder: "" }]);
  const [spec, setSpec] = useState([[]]); // Initialize as an array of arrays
  const [submitted, setSubmitted] = useState<any>([]); // To track the submitted status of each parameter
  const [uids, setUids] = useState<string[]>([]); // State to store UIDs
  const [optSpecName, setOptSpecName] = useState([]);

  const [
    createParamDef,
    {
      data: dataParamDef,
      isSuccess: successParamDef,
      isError: isErrorParamDef,
      isLoading: loadingParamDef,
    },
  ] = useCreateParamDefMutation();
  const [
    updateParamDef,
    {
      isSuccess: successUpdateParamDef,
      isError: isErrorUpdateParamDef,
      isLoading: loadingUpdateParamDef,
    },
  ] = useUpdateParamDefMutation();

  const [
    deleteParamDef,
    {
      data: dataDeleteParamDef,
      isSuccess: successDeleteParamDef,
      isError: isErrorDeleteParamDef,
      isLoading: loadingDeleteParamDef,
    },
  ] = useDeleteParamDefMutation();

  const { data: dataTypeList, isSuccess } = useGetDddFieldsTypeListQuery({});
  const {
    data: dataParamDefUID,
    isSuccess: successParamDefUID,
    isError: isErrorParamDefUID,
    isLoading: loadingParamDefUID,
  } = useGetParamDefUIDQuery(idParam);
  const [
    deleteParamSpec,
    {
      data: dataDeleteParamSpec,
      isSuccess: successDeleteParamSpec,
      isError: isErrorDeleteParamSpec,
      isLoading: loadingDeleteParamSpec,
    },
  ] = useDeleteParamSpecMutation();
  const [
    createParamSpecc,
    {
      isSuccess: successCreateParamSpec,
      isError: isErrorCreateParamSpec,
      isLoading: loadingCreateParamSpec,
    },
  ] = useCreateParamSpecMutation();
  const [
    updateParamSpecc,
    {
      isSuccess: successUpdateParamSpec,
      isError: isErrorUpdateParamSpec,
      isLoading: loadingUpdateParamSpec,
    },
  ] = useUpdateParamSpecMutation();

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(dddFields),
    defaultValues: {
      paramLabel: "",
      paramOrder: "",
    },
  });

  const handleAddField = () => {
    setFields([...fields, { paramLabel: "", paramOrder: "" }]);
    setSpec([...spec, []]); // Add a new empty array for the new parameter
    setSubmitted([...submitted, false]); // Add a new false entry for the submission status
  };

  const handleDeleteField = (index: any) => {
    const defUid: any = fields[index];
    const uid = uids[index]; // Get the UID for the specific parameter
    const updatedFields = fields.filter((_, i) => i !== index);
    const updatedSpecs = spec.filter((_, i) => i !== index);
    const specCurrentIndex = spec.filter((_, i) => i === index);

    if (specCurrentIndex[0].length !== 0) {
      // console.log(specCurrentIndex[0].length)
      Swal.fire({
        title: "Delete All DDD Fields Spec first !!!",
        html: `
            <p style="text-align: left;">
            You need to Delete All DDD Fields Spec Name, Definition and Order First Before Delete Parameter
            </p>
          `,
        icon: "question",
        iconHtml: renderToStaticMarkup(
          <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
        ),
        showCancelButton: false,
        confirmButtonText: "Oke",
      });
      return;
    }

    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure delete this Fields? You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setFields(updatedFields);
        setSpec(updatedSpecs);
        // console.log('delete ==> ', defUid.paramUid)
        deleteParamDef(defUid.paramUid);
      }
    });
  };

  const handleAddSpec = (index: any) => {
    const uid = uids[index]; // Get the UID for the specific parameter
    console.log("uid booor ==> ", uid);
    if (!uid) {
      Swal.fire({
        title: "Submit Parameter First !!!",
        html: `
            <p style="text-align: left;">
            You need to submit Prameter Label and Parameter Order First Before Add Spec
            </p>
          `,
        icon: "question",
        iconHtml: renderToStaticMarkup(
          <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
        ),
        showCancelButton: false,
        confirmButtonText: "Oke",
      });
      return;
    }
    setSpec((prevSpec) => {
      const newSpec: any = [...prevSpec];
      newSpec[index] = [
        ...newSpec[index],
        { specName: "", specDefinition: "", specOrder: "" },
      ];
      return newSpec;
    });
  };

  const onSubmit = (data: any) => {
    console.log("Submitted data:", data);
    // Handle form submission logic here
  };

  useEffect(() => {
    if (dataTypeList) {
      setOptSpecName(
        dataTypeList?.data.map((item: any) => ({
          label: item.dsg_ddd_field_type_name,
          value: item.dsg_ddd_field_type_name,
          example: item.dsg_ddd_field_type_example,
        }))
      );
    }
  }, [dataTypeList]);

  useEffect(() => {
    if (dataParamDef) {
      console.log("dataparamdef ===> ", dataParamDef?.data?.uid); // this is uid
    }
  }, [dataParamDef]);

  // Region Update Value if Existing
  useEffect(() => {
    if (dataParamDefUID) {
      console.log("dataparamdef ===> ", dataParamDefUID?.data); // this is uid
      const result = dataParamDefUID?.data?.reduce((acc: any, item: any) => {
        const {
          dsg_ddd_field_param_definition_uid,
          dsg_ddd_field_param_definition_label,
          dsg_ddd_field_param_definition_order,
          dsg_ddd_param_uid,
          dsg_ddd_param_fieldspec_name,
          dsg_ddd_param_fieldspec_definition,
          dsg_ddd_param_fieldspec_order,
        } = item;

        let existingDefinition: any = acc.find(
          (def: any) =>
            def.dsg_ddd_field_param_definition_uid ===
            dsg_ddd_field_param_definition_uid
        );

        if (!existingDefinition) {
          existingDefinition = {
            dsg_ddd_field_param_definition_uid,
            dsg_ddd_field_param_definition_label,
            dsg_ddd_field_param_definition_order,
            params: [],
          };
          acc.push(existingDefinition);
        }

        if (dsg_ddd_param_uid) {
          existingDefinition.params.push({
            dsg_ddd_param_uid,
            dsg_ddd_param_fieldspec_name,
            dsg_ddd_param_fieldspec_definition,
            dsg_ddd_param_fieldspec_order,
          });
        }

        return acc;
      }, []);
      console.log("result ===> ", result);

      const newFields = result.map(
        (item: {
          dsg_ddd_field_param_definition_label: any;
          dsg_ddd_field_param_definition_order: string;
          dsg_ddd_field_param_definition_uid: string;
        }) => ({
          paramLabel: item.dsg_ddd_field_param_definition_label,
          paramOrder: item.dsg_ddd_field_param_definition_order + "",
          paramUid: item.dsg_ddd_field_param_definition_uid,
        })
      );

      const newSpec: any = result.map((item: { params: any[] }) =>
        item.params.map(
          (specItem: {
            dsg_ddd_param_fieldspec_name: any;
            dsg_ddd_param_fieldspec_definition: any;
            dsg_ddd_param_fieldspec_order: string;
            dsg_ddd_param_uid: string;
          }) => ({
            specName: specItem.dsg_ddd_param_fieldspec_name,
            specDefinition: specItem.dsg_ddd_param_fieldspec_definition,
            specOrder: specItem.dsg_ddd_param_fieldspec_order + "",
            specUid: specItem.dsg_ddd_param_uid,
          })
        )
      );

      // Update the states with the new data
      setUids(
        result.map(
          (item: { dsg_ddd_field_param_definition_uid: any }) =>
            item.dsg_ddd_field_param_definition_uid
        )
      );
      setFields(newFields);
      setSpec(newSpec);

      result.forEach((item: any, paramIndex: number) => {
        setValue(
          `paramLabel${paramIndex}` as any,
          item.dsg_ddd_field_param_definition_label
        );
        setValue(
          `paramOrder${paramIndex}` as any,
          item.dsg_ddd_field_param_definition_order + ""
        );

        item.params.forEach((param: any, specIndex: number) => {
          setValue(
            `fieldSpecName${paramIndex}-${specIndex}` as any,
            param.dsg_ddd_param_fieldspec_name
          );
          setValue(
            `fieldSpecDefinition${paramIndex}-${specIndex}` as any,
            param.dsg_ddd_param_fieldspec_definition
          );
          setValue(
            `fieldSpecOrder${paramIndex}-${specIndex}` as any,
            param.dsg_ddd_param_fieldspec_order + ""
          );
        });
      });
    }
  }, [dataParamDefUID, setValue]);

  useEffect(() => {
    if (successParamDef) {
      Swal.fire({
        icon: "success",
        title: "Success Create",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create DDD Fields",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [successParamDef]);

  useEffect(() => {
    if (successUpdateParamDef) {
      Swal.fire({
        icon: "success",
        title: "Success Update",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Param Label and Param Order",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [successUpdateParamDef]);

  useEffect(() => {
    if (successDeleteParamDef || successDeleteParamSpec) {
      showAlert('success', 'Success Deleted', 'Success Deleted');
    }
  }, [successDeleteParamDef, successDeleteParamSpec]);

  useEffect(() => { 
    if (isErrorDeleteParamDef || isErrorDeleteParamSpec) {
      showAlert('error', 'Error Deleted', 'Error Deleted');
    }
  }, [isErrorDeleteParamDef, isErrorDeleteParamSpec]);

  useEffect(() => {
    if (isErrorParamDef) {
      Swal.fire({
        icon: "error",
        title: "Error Create",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Create DDD Fields",
      });
    }
  }, [isErrorParamDef]);

  useEffect(() => {
    if (isErrorUpdateParamDef) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Param Label ",
      });
    }
  }, [isErrorUpdateParamDef]);

  useEffect(() => {
    if (successCreateParamSpec || successUpdateParamSpec) {
      Swal.fire({
        icon: "success",
        title: "Success Submit Field Spec",
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Create DDD Fields",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  }, [successCreateParamSpec, successUpdateParamSpec]);

  const handleSubmitt = (index: any) => {
    const paramLabel = watch(`paramLabel${index}` as any);
    const paramOrder = watch(`paramOrder${index}` as any);
    let tempParam = {
      dsg_ddd_field_param_definition_label: paramLabel,
      dsg_ddd_field_param_definition_order: Number(paramOrder),
      dsg_ddd_uid: idParam,
    };
    console.log("tempParam ===> ", tempParam);

    // createParamDef(tempParam);
    createParamDef(tempParam).then((response: any) => {
      const newUid = response?.data?.data?.uid; // Assuming this is where the UID comes from
      console.log("penyeselan ===> ", response);
      setUids((prevUids) => {
        const updatedUids = [...prevUids];
        updatedUids[index] = newUid; // Store UID at the index corresponding to the parameter
        return updatedUids;
      });
    });
  };

  const handleUpdateParam = (index: any) => {
    const fieldUid: any = fields[index];
    console.log("index ===> ", index);
    console.log("fields ===> ");
    if (fieldUid?.paramUid) {
      let paramUidField = {
        dsg_ddd_field_param_definition_label: watch(
          `paramLabel${index}` as any
        ),
        dsg_ddd_field_param_definition_order: Number(
          watch(`paramOrder${index}` as any)
        ),
        dsg_ddd_uid: idParam,
      };
      // fieldUid.paramUid
      updateParamDef({ uid: fieldUid.paramUid, body: paramUidField });
    }
  };
  // Region Create Param Spec
  const handleSubmitSpec = (index: number) => {
    const uid = uids[index]; // Get the UID for the specific parameter
    console.log("uid ===> ", uid);

    if (!uid) {
      console.error("UID is not available. Please submit the parameter first.");
      return;
    }

    const specParamUid = spec[index];
    const newParamSpec: any[] = [];
    const updateParamSpec: any[] = [];

    specParamUid.forEach((specItem: any, specIndex: number) => {
      const specDataUpdate = {
        dsg_ddd_param_fieldspec_name: watch(
          `fieldSpecName${index}-${specIndex}` as any
        ),
        dsg_ddd_param_fieldspec_definition: watch(
          `fieldSpecDefinition${index}-${specIndex}` as any
        ),
        dsg_ddd_param_fieldspec_order: Number(
          watch(`fieldSpecOrder${index}-${specIndex}` as any)
        ),
        dsg_ddd_field_param_definition_uid: uid,
        dsg_ddd_param_uid: specItem.specUid,
      };
      const specDataNew = {
        dsg_ddd_param_fieldspec_name: watch(
          `fieldSpecName${index}-${specIndex}` as any
        ),
        dsg_ddd_param_fieldspec_definition: watch(
          `fieldSpecDefinition${index}-${specIndex}` as any
        ),
        dsg_ddd_param_fieldspec_order: Number(
          watch(`fieldSpecOrder${index}-${specIndex}` as any)
        ),
        dsg_ddd_field_param_definition_uid: uid,
      };

      if (specItem.specUid) {
        updateParamSpec.push(specDataUpdate);
      } else {
        newParamSpec.push(specDataNew);
      }
    });

    if (updateParamSpec.length > 0) {
      updateParamSpecc(updateParamSpec);
    }
    if (newParamSpec.length > 0) {
      createParamSpecc(newParamSpec);
    }
    console.log("new: ", newParamSpec);
    console.log("update: ", updateParamSpec);
  };

  const handleChangeFormSpecName = (
    paramIndex: number,
    specIndex: number,
    item: any
  ) => {
    // Update the placeholder for the specific spec field
    console.log("paramIndex ===> ", paramIndex);
    console.log("specIndex ===> ", specIndex);
    console.log("item ===> ", item);
    setValue(
      `fieldSpecDefinition${paramIndex}-${specIndex}` as any,
      `ex: ${item.example}`
    );
    // const newPlaceholders = { ...placeholders };
    // newPlaceholders[`specPlaceholder${paramIndex}-${specIndex}`] = item.example;
    // setPlaceholders(newPlaceholders);
  };
  const handleDeleteSpec = (paramIndex: any, specIndex: any) => {
    let specUidTemp: any = spec[paramIndex][specIndex];
    console.log("spec haha ==> ", specUidTemp);
    console.log("spec haha ==> ", uids);
  
    Swal.fire({
      title: "Delete Confirmation",
      text: "Are you sure delete this Fields? You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (specUidTemp.specUid) {
          deleteParamSpec(specUidTemp.specUid).then(() => {
            // Clear the values for the deleted spec
            setValue(`fieldSpecName${paramIndex}-${specIndex}` as any, "");
            setValue(`fieldSpecDefinition${paramIndex}-${specIndex}` as any, "");
            setValue(`fieldSpecOrder${paramIndex}-${specIndex}` as any, "");
  
            // Update the remaining spec field indices
            const updatedSpec = [...spec];
            updatedSpec[paramIndex].splice(specIndex, 1);
  
            // If there are specs after the deleted one, adjust their indices
            updatedSpec[paramIndex].forEach((_, idx) => {
              if (idx >= specIndex) {
                setValue(`fieldSpecName${paramIndex}-${idx}` as any, getValues(`fieldSpecName${paramIndex}-${idx + 1}` as any));
                setValue(`fieldSpecDefinition${paramIndex}-${idx}` as any, getValues(`fieldSpecDefinition${paramIndex}-${idx + 1}` as any));
                setValue(`fieldSpecOrder${paramIndex}-${idx}` as any, getValues(`fieldSpecOrder${paramIndex}-${idx + 1}` as any));
              }
            });
  
            setSpec(updatedSpec);
          });
        } else {
          const updatedSpec = [...spec];
          updatedSpec[paramIndex].splice(specIndex, 1);
  
          // Update the remaining spec field indices
          updatedSpec[paramIndex].forEach((_, idx) => {
            if (idx >= specIndex) {
              setValue(`fieldSpecName${paramIndex}-${idx}` as any, getValues(`fieldSpecName${paramIndex}-${idx + 1}` as any));
              setValue(`fieldSpecDefinition${paramIndex}-${idx}` as any, getValues(`fieldSpecDefinition${paramIndex}-${idx + 1}` as any));
              setValue(`fieldSpecOrder${paramIndex}-${idx}` as any, getValues(`fieldSpecOrder${paramIndex}-${idx + 1}` as any));
            }
          });
  
          setSpec(updatedSpec);
        }
      }
    });
  };

  useEffect(() => {
    if (successDeleteParamSpec) {
      showAlert('success', 'Success Deleted', 'Success Deleted');
    }
  }, [successDeleteParamSpec]);

  useEffect(() => {
    if (isErrorDeleteParamSpec) {
      showAlert('error', 'Error Delete', 'Error Delete');
    }
  }, [isErrorDeleteParamSpec]);
  

  return (
    <DetailDataSkeleton
      isLoading={loadingParamDefUID}
      isLoadingEvent={loadingParamDef || loadingUpdateParamDef}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Typography variant="h3" fontFamily="Open Sans" mb={3}>
              Detail DDD Fields
            </Typography>
          </Grid>
          {fields.map((field, paramIndex) => (
            <React.Fragment key={paramIndex}>
              <Grid item lg={5}>
                <InputForm
                  name={`paramLabel${paramIndex}`}
                  label="Parameter Label"
                  placeholder="type Parameter Label"
                  disabled={false}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  maxLength={500}
                  required
                />
              </Grid>
              <Grid item lg={5}>
                <InputForm
                  name={`paramOrder${paramIndex}`}
                  label="Parameter Order"
                  placeholder="Parameter Order"
                  disabled={false}
                  isLoading={false}
                  control={control}
                  errors={errors}
                  maxLength={500}
                  required
                  type="number"
                />
              </Grid>
              <Grid
                item
                lg={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                {!uids[paramIndex] ? (
                  <Button
                    variant="contained"
                    sx={{ background: "#006fbb" }}
                    onClick={() => handleSubmitt(paramIndex)}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    // sx={{ background: "#006fbb" }}
                    color="warning"
                    onClick={() => handleUpdateParam(paramIndex)}
                  >
                    Update
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => handleDeleteField(paramIndex)}
                  color="error"
                >
                  Delete
                </Button>
              </Grid>
              {spec[paramIndex]?.length > 0 ? (
                <>
                  <SpecInfoLabel dataFieldTypes={dataTypeList}/>
                </>
              ) : (
                <Grid item lg={9}></Grid>
              )}
              <Grid item lg={3}>
                <Button
                  variant="contained"
                  sx={{ background: "#006fbb", marginRight: "10px" }}
                  onClick={() => handleAddSpec(paramIndex)}
                  color="warning"
                >
                  Add Spec
                </Button>
              </Grid>

              {spec[paramIndex]?.map((specField, specIndex) => (
                <React.Fragment key={specIndex}>
                  <Grid item lg={2}></Grid>
                  <Grid item lg={2}>
                    <SelectFormm
                      name={`fieldSpecName${paramIndex}-${specIndex}`}
                      placeholder="Spec Name"
                      disabled={false}
                      isLoading={false}
                      control={control}
                      errors={errors}
                      defaultValue={1}
                      options={optSpecName}
                      onChangeForm={(item) =>
                        handleChangeFormSpecName(paramIndex, specIndex, item)
                      }
                      required
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <InputForm
                      name={`fieldSpecDefinition${paramIndex}-${specIndex}`}
                      placeholder="Spec Definition"
                      disabled={false}
                      isLoading={false}
                      control={control}
                      errors={errors}
                      maxLength={500}
                      required
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <InputForm
                      name={`fieldSpecOrder${paramIndex}-${specIndex}`}
                      placeholder="Spec Order"
                      disabled={false}
                      isLoading={false}
                      control={control}
                      errors={errors}
                      maxLength={500}
                      required
                      type="number"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    display={"flex"}
                    alignItems={"center"}
                    marginBottom={2}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleDeleteSpec(paramIndex, specIndex)}
                      color="error"
                    >
                      Delete
                    </Button>
                  </Grid>
                </React.Fragment>
              ))}
              {spec[paramIndex]?.length > 0 && (
                <Grid
                  item
                  lg={11}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  marginBottom={2}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => handleSubmitSpec(paramIndex)}
                  >
                    Submit All Spec
                  </Button>
                </Grid>
              )}
              <Grid item lg={12}>
              <hr />
              </Grid>

            </React.Fragment>
          ))}
          <Grid item lg={10}></Grid>
          <Grid item lg={2} display={"flex"} justifyContent={"space-around"}>
            <Button variant="contained" color="gray" onClick={() =>navigate(-1)}>
              Back
            </Button>
            <Button variant="contained" onClick={handleAddField}>
              Add Parameter
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default DddFieldsMngDetail;
