// import { instance, instanceEndUser } from "./service/instance";
import { apiSlice } from '../api/apiSlice'

  export const logEventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLogEventsList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/events`,
                    params: params
                }
            },
            providesTags:['GetLogEventsList'],
        }),
        getKeySignList: builder.query<any, any>({
            query: params => {
                return {
                    url: `/sign-history/list`,
                    params: params
                }
            },
            providesTags:['GetKeySignList'],
        }),
  })
})

export const {
useGetLogEventsListQuery,
useGetKeySignListQuery
} = logEventsApiSlice;