import IRoutes from "../interfaces/IRoutes";
import PageAccess from "../pages/pageAccess/PageAccess";
import PageAccessField from "../pages/pageAccess/PageAccessField";

const PageAccessRoute: Array<IRoutes> = [
  {
    element: <PageAccess />,
    path: "/page-access",
  },
  {
    element: <PageAccessField />,
    path: "/page-access/create",
  },
  {
    element: <PageAccessField />,
    path: "/page-access/edit/:id",
  },
];

export { PageAccessRoute };
