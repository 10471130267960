import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const schema = yup.object({
  title: yup.string().required(),
});

const UserAccountSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [daCertName, setDaCertName] = useState("");
  const [daCertCN, setDaCertCN] = useState("");
  const [status, setStatus] = useState("");
  const [enrolledStart, setEnrolledStart] = useState("");
  const [enrolledEnd, setEnrolledEnd] = useState("");
  const [userActive, setUserActive] = useState(""); // TODO: NEED PARAMETER FROM API
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    daCertName: string;
    daCertCN: string;
    status: string;
    enrolledStart: string;
    enrolledEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      daCertName: "",
      daCertCN: "",
      status: "",
      enrolledStart: "",
      enrolledEnd: "",
    },
  });

  const statusDropdown = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];

  const handleDaCertName = (e: string) => {
    setDaCertName(e);
  };
  const handleDaCertCN = (e: string) => {
    setDaCertCN(e);
  };
  const handleStatus = (e: any) => {
    // setStatus(e.value);
    console.log("haha ===> ", e);
    if (e !== null) {
      setStatus(e.value);
    } else {
      setStatus("");
    }
  };
  // const handleStatusSelect = (e: any) => { setUserActive(e.value); };
  const handleEnrolledStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
      if (dayjs(e).isAfter(dayjs(enrolledEnd))) {
        setAllDate(NewDate);
      } else {
        setEnrolledStart(NewDate);
      }
    } else {
      NewDate = "";
      setAllDate(NewDate);
    }
  };
  const setAllDate = (date: string) => {
    reset({
      // deviceId: deviceId,
      // hostname: hostname,
      // enrolledStart: date,
      // enrolledEnd: date,
      // status: userActive,
      daCertName,
      daCertCN,
      status,
      enrolledStart: date,
      enrolledEnd: date,
    });
    setEnrolledStart(date);
    setEnrolledEnd(date);
  };
  const handleEnrolledEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    if (conditionCheck()) setEnrolledEnd(NewDate);
  };
  const conditionCheck = () => {
    if (enrolledStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Please select Start Created",
      });
      reset({
        // deviceId: deviceId,
        // hostname: hostname,
        // enrolledStart: "",
        // enrolledEnd: "",
        // status: userActive,
        daCertName,
        daCertCN,
        status,
        enrolledStart: "",
        enrolledEnd: "",
      });
      setEnrolledEnd("");
      return false;
    } else {
      return true;
    }
  };

  useDebouncedEffect(
    () => setSearchFilterDebounced(daCertName),
    [daCertName],
    500
  );
  useDebouncedEffect(() => setSearchFilterDebounced(daCertCN), [daCertCN], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(status), [status], 500);
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledStart),
    [enrolledStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(enrolledEnd),
    [enrolledEnd],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      adm_user_uid: daCertName,
      adm_user_email: daCertCN,
      adm_user_status: status,
      adm_user_last_login_start: enrolledStart,
      adm_user_last_login_end: enrolledEnd,
    });
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="daCertName"
          errors={errors}
          control={control}
          required
          placeholder="User ID"
          maxLength={100}
          isLoading={false}
          onChange={handleDaCertName}
        />
        <InputForm
          name="daCertCN"
          errors={errors}
          control={control}
          required
          placeholder="Email"
          maxLength={100}
          isLoading={false}
          onChange={handleDaCertCN}
        />
        <DatePickerForm
          name="enrolledStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Last Login From"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledStart}
        />
        <DatePickerForm
          name="enrolledEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="LastLogin to"
          disabled={false}
          isLoading={false}
          required
          onChange={handleEnrolledEnd}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={statusDropdown}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatus}
          isClear
        />
      </Box>
    </Box>
  );
};

export default UserAccountSearch;
