import * as yup from "yup";
import { validationNotEmpty } from "./defaultValidations";

export default yup.object({
  certName: validationNotEmpty("Domain Authority Certificate Name"),
  certCN: validationNotEmpty("Certificate Common Name"),
  certIssuerCN: validationNotEmpty("Certificate Issuer Common Name"),
  certSN: validationNotEmpty("Certificate Serial Number"),
  uploadCert: validationNotEmpty("Upload DA/Root Certificate"),
  keyName: validationNotEmpty("Key Name"),
  status: validationNotEmpty("Status"),
});