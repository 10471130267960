import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { useGetKeyMngByUidMutation } from "../../store/feature/service/keyApiSlice";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import { renderToStaticMarkup } from "react-dom/server";
import digsigCert from "../../utils/validation/digsigCert";
import {
  useGetCertByUidMutation,
  useGetDigsigUidNamesQuery,
  useUpdateCertDigMutation,
  useUploadCertDigMutation,
} from "../../store/feature/service/digsigApiSlice";
import digsigCertUpdate from "../../utils/validation/digsigCertUpdate";

const DigsigCertField = () => {
  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const [optKeyNameSelect, setOptKeyNameSelect] = useState([]);
  const [cert, setCert] = useState("");

  const [getKeyMngByUid, { data, isError, isSuccess }] =
    useGetKeyMngByUidMutation();
  const {
    data: dataDsgUidName,
    isError: errorDsgUidName,
    isSuccess: successDsgUidName,
  } = useGetDigsigUidNamesQuery({});

  const [
    getCertByUid,
    {
      data: dataGetCertByUid,
      isError: errorGetCertByUid,
      isSuccess: successGetCertByUid,
    },
  ] = useGetCertByUidMutation();

  const [
    uploadCertDig,
    { data: dataUpload, isError: errorUpload, isSuccess: successUpload },
  ] = useUploadCertDigMutation();

  const [
    updateCertDig,
    { data: dataUpdate, isError: errorUpdate, isSuccess: successUpdate },
  ] = useUpdateCertDigMutation();

  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    certName: string;
    certCN: string;
    certSN: string;
    certIssuerCN: string;
    uploadCert: string;
    keyName: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(editFgroup ? digsigCertUpdate : digsigCert),
    defaultValues: {
      certName: "",
      certCN: "",
      certSN: "",
      certIssuerCN: "",
      uploadCert: "",
      keyName: "",
      status: "",
    },
  });

  // REGION: CHANGE STATE
  // useEffect(() => {
  //   getKeyMngByUid(idParam.id);
  // }, [idParam]);

  useEffect(() => {
    if (data?.data) {
      console.log("data ===> ", data);
      const keyData = data?.data;
      setValue("certName", keyData.dsg_key_name);
      setValue("certIssuerCN", keyData.dsg_key_creation_date);
      setValue("certCN", keyData.dsg_key_expiry_date);
      setValue("certSN", keyData.dsg_key_revoke_date);
      setValue("uploadCert", keyData.dsg_key_revoke_reason);
      setValue("keyName", keyData.dsg_ddd_name);
      setValue("status", state.createdAt);
    }
  }, [data]);

  useEffect(() => {
    if (dataDsgUidName) {
      setOptKeyNameSelect(
        dataDsgUidName?.data.map((item: any) => ({
          label: item.dsg_key_name,
          value: item.dsg_key_name,
        }))
      );
    }
  }, [dataDsgUidName]);

  // REGION: DISPATCH INSERT & UPDATE DATA

  const onSubmit = (e: any) => {
    let tempSubmitUpload = {
      dsg_certificate_common_name: e.certCN,
      dsg_certificate_data: cert,
      dsg_certificate_issuer_cn: e.certIssuerCN,
      dsg_certificate_key_name: e.keyName,
      dsg_certificate_name: e.certName,
      dsg_certificate_serialnumber: e.certSN,
      dsg_certificate_status: e.status,
    };
    let tempSubmitUpdate = {
      dsg_certificate_common_name: e.certCN,
      dsg_certificate_issuer_cn: e.certIssuerCN,
      dsg_certificate_name: e.certName,
      dsg_certificate_serialnumber: e.certSN,
      dsg_certificate_status: e.status,
    };

    if (editFgroup) {
      updateCertDig({ id: idParam.id, body: tempSubmitUpdate });
    } else {
      uploadCertDig(tempSubmitUpload);
    }
  };

  const showFirstAlert = () => {
    Swal.fire({
      title: "Your changes won’t be saved",
      html: `
            <p style="text-align: left;">
              We won’t be able to save your data if you move away from this page.
            </p>
          `,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      iconHtml: renderToStaticMarkup(
        <WarningIcon style={{ fontSize: "50px", color: "#d33" }} />
      ),
      confirmButtonText: "Discard Data",
      cancelButtonText: "Go back",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/domain-authority-certificate");
      }
    });
  };

  const handleRevoke = (e: any) => {
    console.log("handle revoke ===> ");
    showFirstAlert();
  };

  const handleDestroy = (e: any) => {
    console.log("handle revoke ===> ");
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "active",
    },
    {
      label: "INACTIVE",
      value: "inactive",
    },
  ];
  const handleUploadCert = (e: any) => {
    const file = e[0];
    console.log("file here ", file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(",")[1];
        if (base64String) {
          // setValue("uploadCert", base64String); // Set the Base64 string to the form field
          setCert(base64String);
          console.log("Base64 String: ", base64String); // Optional: log the Base64 string for debugging
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  useEffect(() => {
    if (editFgroup) {
      console.log("finally called");
      getCertByUid(idParam.id);
    }
  }, [editFgroup]);

  useEffect(() => {
    if (dataGetCertByUid) {
      const dataGet = dataGetCertByUid?.data;
      setValue("certName", dataGet.dsg_certificate_name);
      setValue("certCN", dataGet.dsg_certificate_common_name);
      setValue("certSN", dataGet.dsg_certificate_serialnumber);
      setValue("certIssuerCN", dataGet.dsg_certificate_issuer_cn);
      setValue("keyName", dataGet.dsg_certificate_key_name);
      setValue("status", dataGet.dsg_certificate_status);
      console.log("hujan samarkan derasnya ===> ", dataGetCertByUid?.data);
    }
  }, [dataGetCertByUid]);

  useEffect(() => {
    if (successUpload) {
      Swal.fire({
        icon: "success",
        title: "Success Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Upload Digsig Certificate",
      }).then(() => navigate("/digsig-certificate"));
    }
  }, [successUpload]);

  useEffect(() => {
    if (errorUpload) {
      Swal.fire({
        icon: "error",
        title: "Error Upload",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Upload Digsig Certificate",
      });
    }
  }, [errorUpload]);

  useEffect(() => {
    if (successUpdate) {
      Swal.fire({
        icon: "success",
        title: "Success Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Success Update Digsig Certificate",
      }).then(() => navigate("/digsig-certificate"));
    }
  }, [successUpdate]);

  useEffect(() => {
    if (errorUpdate) {
      Swal.fire({
        icon: "error",
        title: "Error Update",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: "#051438",
        text: "Error Update Digsig Certificate",
      });
    }
  }, [errorUpdate]);

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
      // isLoading={isLoadingOcspConfig}
      // isLoadingEvent={isLoadingEvent}
      inputCount={6}
      titleWidth={"350px"}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          {editFgroup
            ? "Update DIGSIG Certificate"
            : "Upload DIGSIG Certificate"}
        </Typography>
        <InputForm
          name="certName"
          label="Certificate Name"
          placeholder="Type Certificate Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="certCN"
          label="Certificate Common Name"
          placeholder="Type Certificate Common Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        <InputForm
          name="certSN"
          label="Certificate Serial Number"
          placeholder="Type Certificate Serial Number"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />

        <InputForm
          name="certIssuerCN"
          label="Certificate Issuer Common Name"
          placeholder="Type Certificate Issuer Common Name"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          maxLength={500}
          required
        />
        {!editFgroup && (
          <>
            <InputForm
              name="uploadCert"
              label="Upload DIGSIG Certificate"
              placeholder="Type Upload DA/Root Certificate"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              maxLength={500}
              onChange={handleUploadCert}
              required
              type="file"
            />
            <SelectFormm
              name="keyName"
              label="Key Name"
              defaultValue={0}
              options={optKeyNameSelect}
              placeholder="Please Select"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </>
        )}
        <SelectFormm
          name="status"
          label="Certificate Status"
          defaultValue={0}
          options={optStatusSelect}
          placeholder="Please Select"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            type="button"
            color="gray"
            onClick={handleRevoke}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            {editFgroup ? "Update" : "Upload"}
          </Button>
        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default DigsigCertField;
