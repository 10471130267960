import { encrypt } from "../../../utils/hash/password";
import { apiSlice } from "../api/apiSlice";
import { ILogin } from "../reducers/userReducer";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitLogin: builder.mutation<any, any>({
      query: (credentials) => {
        const { Email, Password } = credentials;
        const pasEnc = encrypt(Password);
        return {
          url: "/authentication/login",
          method: "POST",
          body: {
            email: Email,
            password: pasEnc,
          },
        };
      },
      invalidatesTags: ["submitLogin", "GetUserAccRole", "GetDashboardInfo"],
    }),
    confirmPassword: builder.mutation<any, any>({
      query: (credentials) => {
        const { Email, Password, newPassword } = credentials;
        const pasEnc = Password;
        return {
          url: "/user-admin/change-password",
          method: "PUT",
          body: {
            adm_user_email: Email,
            adm_user_old_pwd_hash: encrypt(Password),
            adm_user_pwd_hash: encrypt(newPassword),
          },
        };
      },
      invalidatesTags: ["ConfirmPassword", "GetUserAccRole"],
    }),
  }),
});

export const { useSubmitLoginMutation, useConfirmPasswordMutation } =
  userApiSlice;
