import IRoutes from "../interfaces/IRoutes";
import ImportKey from "../pages/importKey/ImportKey";
import ImportKeyField from "../pages/importKey/ImportKeyField";

const ImportKeyRoute: Array<IRoutes> = [
    {
        element: <ImportKey />,
        path: "/import-key",
    },
    {
        element: <ImportKeyField />,
        path: "/import",
    }
];

export { ImportKeyRoute };
