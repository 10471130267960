import { Box, Button, Chip, Stack, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import { useState } from "react";

const ApplicationLogTable = ({
  data,
  params,
  setParams,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const { totalConfig } = useAppSelector((store: RootState) => store.satpasDevice);

  // REGION: MODAL STATE
  const [open, setOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");

  // REGION: MODAL STYLE
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {
    // REGION: SHOW DESCRIPTION MODAL
    const onShowDescription = (e: any) => {
      e.stopPropagation();
      setSelectedDescription(params.row.description || "No description available");
      setOpen(true);
    };

    // REGION: RETURN TSX
    return (
      <>
      <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={onShowDescription}>
            Description
          </Button>
          {/* <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button> */}
      </Stack>
      </>
    );
  };


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "module",
      headerName: "Module",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "function",
      headerName: "Function",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        if( params.row.severity !== null ) {
          switch(params.row.severity.toLowerCase()) {
            case "low": {
              return <Chip label="LOW" color="success" variant="outlined" />
            }
            case "medium": {
              return <Chip label="MEDIUM" color="warning" variant="outlined" />
            }
            case "high": {
              return <Chip label="HIGH" color="secondary" variant="outlined" />
            }
          }
        } else {
          return (
            ""
          )
        }
      },
    },
    {
      field: "time",
      headerName: "Time Log",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];


  // REGION: INIT ROWS
  console.log('data ===> ', data)
  const dataRow = data?.data !== undefined && data?.data?.data !== null ? data?.data?.data : []
  const totalConfig = data?.data !== undefined ? data?.data?.total_records : 0
  const rows = dataRow?.map((row: any, index: any) => ({
    id: index,
    // uid: row.uc_ocsp_config_uid,
    description: row.event_description,
    module: row.event_module,
    function: row.event_function,
    severity: row.event_severity,
    type: row.event_type,
    time: row.event_time,
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalConfig} />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="description-modal"
        aria-describedby="description-modal-content"
      >
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="description-modal" variant="h6" component="h2">
              Description
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="description-modal-content" sx={{ mt: 2 }}>
            {selectedDescription}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ApplicationLogTable;
